import { Link } from "react-router-dom";

interface AuthorAvatarImageProps {
    photourl?: string | null | undefined;
    pixelWidth: number
}

const AuthorAvatarImage: React.FC<AuthorAvatarImageProps> = ({
    photourl,
    pixelWidth
}: AuthorAvatarImageProps) => {
    return (
        <img
        src={photourl || "/avatar-1577909_640.png"}
        className="rounded-circle"
        style={{ width: `${pixelWidth}px` }}
        alt="Avatar"
    />
    );
};

export default AuthorAvatarImage;
