import { useState } from "react";
import { Modal, Button, Card, CardFooter, CloseButton } from "reactstrap";
import PocService from "../services/PocService";
import toast from "react-hot-toast";
import { Trash3 } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { handleSubmitFormErrorResponse } from "../utils/FormUtil";
import "../styles/ModalDialogFooter.scss";
import { canEdit } from "utils/functions";

interface DeletePocProps {
    pocId: number;
    externalIds: string[];
    isAdmin: boolean;
    onDeleteSuccess: (arg: number) => void;
}

const DeletePoc: React.FC<DeletePocProps> = ({
    pocId,
    externalIds,
    isAdmin,
    onDeleteSuccess,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitError, setSubmitError] = useState<string>("");
    const { t } = useTranslation();
    const isAllowed: boolean = canEdit(externalIds) || isAdmin;

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
        setSubmitError("");
    };

    const handleDelete = async () => {
        try {
            await PocService.deletePoc(pocId);
            toast.success(t("toast.successDeletingPoc"));
            toggleModal();
            onDeleteSuccess(pocId);
        } catch (error) {
            console.error("Error deleting PoC:", error);
            handleSubmitFormErrorResponse(
                error,
                null,
                setSubmitError,
                () => {},
                t,
            );
            toast.error(submitError || t("toast.errorDeletingPoc"));
        }
    };

    return (
        <>
            {isAllowed && (
                <Button onClick={toggleModal}>
                    <Trash3 color="#E23D28" size={30} />
                </Button>
            )}

            <Modal
                isOpen={isModalOpen}
                toggle={toggleModal}
                className="poc-modal">
                <Card className="poc-card">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {t("deletePoc.confirmDeleteDialog")}
                        </h5>
                        <CloseButton
                            onClick={toggleModal}
                            className="ml-auto"
                        />
                    </div>
                    <CardFooter className="modal-poc-footer">
                        <Button color="danger" onClick={handleDelete}>
                            {t("deletePoc.delete")}
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>
                            {t("deletePoc.cancel")}
                        </Button>
                    </CardFooter>
                    {submitError && (
                        <div className="error-message">{submitError}</div>
                    )}
                </Card>
            </Modal>
        </>
    );
};

export default DeletePoc;
