import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Input,
    Label,
    FormFeedback,
} from "reactstrap";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Layout from "layouts/Layout";
import "../styles/SuggestPoC.scss";
import SuggestionService from "services/SuggestionService";
import {
    handleSubmitFormErrorResponse,
    SuggestionFormValues,
} from "../utils/FormUtil";
import { useFormPrompt } from "hooks/useFormPrompt";
import LeavePageConfirmationDialog from "components/LeavePageConfimationDialog";

const SuggestPoC = () => {
    const [submitError, setSubmitError] = useState("");
    const { t } = useTranslation();
    const { id } = useParams();
    const navigate = useNavigate();
    const isEditMode = Boolean(id);
    useEffect(() => {
        if (isEditMode && id) {
            SuggestionService.getSuggestionById(parseInt(id))
                .then((response) => {
                    const { name, description } = response.data;
                    formik.setValues({ name, description });
                    setFormikInitialValues({
                        name: response.data.name,
                        description: response.data.description,
                    });
                })
                .catch((error) => {
                    console.error("Error fetching suggestion:", error);
                    toast.error(t("error.fetchError"));
                });
        }
    }, [id, isEditMode, t]);

    const formik = useFormik<SuggestionFormValues>({
        initialValues: {
            name: "",
            description:
                "Problem Description:\n\nTarget Audience:\n\nProposed Solution:\n\n",
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .trim()
                .max(255, t("createPoC.nameMaxLength"))
                .required(t("createPoC.nameRequired")),
            description: Yup.string()
                .trim()
                .required(t("createPoC.descriptionRequired")),
        }),
        onSubmit: (values, { setSubmitting }) => {
            const trimmedValues = {
                ...values,
                name: values.name.trim(),
            };
            const action = isEditMode
                ? SuggestionService.updateSuggestion(
                      parseInt(id!),
                      trimmedValues,
                  )
                : SuggestionService.suggestPoC(trimmedValues);

            action
                .then(() => {
                    const successMessage = isEditMode
                        ? t("suggestPoC.success")
                        : t("suggestPoC.success");
                    toast.success(successMessage);
                    if (id) {
                        navigate(`/suggested-pocs/${id}`);
                    } else {
                        navigate("/");
                    }
                })
                .catch((error) => {
                    handleSubmitFormErrorResponse(
                        error,
                        formik,
                        setSubmitError,
                        () => setSubmitting(false),
                        t,
                    );
                });
        },
    });

    const [formikInitialValues, setFormikInitialValues] = useState(
        formik.initialValues,
    );

    const isDirty = () => {
        return (
            formikInitialValues.name !== formik.values.name ||
            formikInitialValues.description !== formik.values.description
        );
    };

    useFormPrompt(isDirty());

    return (
        <Layout>
            <Card className="poc-card suggest-poc-form">
                <CardBody>
                    <h5>{t("suggestPoC.suggestNewPoC")}</h5>
                    <Form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <Label for="name">{t("createPoC.name")} *</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setSubmitError("");
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                invalid={
                                    formik.touched.name && !!formik.errors.name
                                }
                            />
                            <FormFeedback>{formik.errors.name}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="description">
                                {t("suggestPoC.description")} *
                            </Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                invalid={
                                    formik.touched.description &&
                                    !!formik.errors.description
                                }
                                style={{ height: "300px" }}
                            />
                            <FormFeedback>
                                {formik.errors.description}
                            </FormFeedback>
                        </FormGroup>
                        {submitError && (
                            <div className="text-danger">{submitError}</div>
                        )}

                        <Button
                            type="submit"
                            color="primary"
                            className="submit-button">
                            {t("suggestPoC.save")}
                        </Button>
                    </Form>
                    <LeavePageConfirmationDialog hasChanges={isDirty()} />
                </CardBody>
            </Card>
        </Layout>
    );
};

export default SuggestPoC;
