import UserRole from "auth/enums/UserRole"
import AuthService from "auth/AuthService";

export const canEdit = (externalIds: string[]): boolean => {
    const { isAuthenticated, hasAnyOfRoles, getUserId } = AuthService;
    let isAllowed = false;
    const roles = [UserRole.ADMIN, UserRole.CUSTOMER, UserRole.EMPLOYEE];

    if (isAuthenticated()) {
        switch (hasAnyOfRoles(roles)) {
            case UserRole.ADMIN:
                isAllowed = true;
                break;
            case UserRole.EMPLOYEE:
                const extId = getUserId();
                const isPresent = externalIds.indexOf(extId);
                if (isPresent !== -1) {
                    isAllowed = true;
                }
                break;
            case UserRole.CUSTOMER:
                isAllowed = false;
                break;
        }
    }
    return isAllowed;
}