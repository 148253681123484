import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import * as Yup from "yup";
import {
    Card,
    CardBody,
    CardTitle,
    Form,
    FormGroup,
    Input,
    Label,
    Button,
    FormFeedback,
} from "reactstrap";
import "../styles/CreatePoC.scss";
import LeavePageConfirmationDialog from "components/LeavePageConfimationDialog";
import PocService from "../services/PocService";
import { handleSubmitFormErrorResponse } from "../utils/FormUtil";
import { FormValues } from "../utils/FormUtil";
import { useFormPrompt } from "hooks/useFormPrompt";
import { useTranslation } from "react-i18next";

interface CreatePocProps {
    onPocCreated?: (newPocId: number) => void;
    pocId?: number;
    setIsCreateFormDirty?: (isDirty: boolean) => void;
}

const CreatePoC: React.FC<CreatePocProps> = ({
    onPocCreated,
    pocId,
    setIsCreateFormDirty,
}) => {
    const [submitError, setSubmitError] = useState("");
    const { pocId: pocIdParam } = useParams<{ pocId?: string }>();
    const effectivePocId = pocId ?? parseInt(pocIdParam || "", 10);
    const { t } = useTranslation();

    const isEditMode = !!effectivePocId;
    const formik = useFormik<FormValues>({
        initialValues: {
            name: "",
            slug: "",
            description: "",
        },
        validationSchema: Yup.object({
            name: Yup.string()
                .trim()
                .required(t("createPoC.nameRequired"))
                .max(255, t("createPoC.nameMaxLength")),
            slug: Yup.string()
                .matches(/^[a-z0-9-]+$/, t("createPoC.slugValidation"))
                .required(t("createPoC.slugRequired")),
            description: Yup.string()
                .trim()
                .required(t("createPoC.descriptionRequired")),
        }),
        onSubmit: (values, { setSubmitting }) => {
            const trimmedValues = {
                ...values,
                name: values.name.trim(),
            };
            const action = isEditMode
                ? () => PocService.updatePoC(pocId!, trimmedValues)
                : () => PocService.createPoC(trimmedValues);

            action()
                .then((response) => {
                    const id = isEditMode ? +pocId! : response.data.id;
                    if (!isEditMode) {
                        onPocCreated && onPocCreated(id);
                    }
                    setFormikInitialValues({
                        name: response.data.name,
                        slug: response.data.slug,
                        description: response.data.description,
                    });
                    setIsCreateFormDirty?.(false);
                    toast.success(t("toast.saved"));
                })
                .catch((error) => {
                    handleSubmitFormErrorResponse(
                        error,
                        formik,
                        setSubmitError,
                        () => setSubmitting(false),
                        t,
                    );
                });
        },
    });

    const [formikInitialValues, setFormikInitialValues] = useState(
        formik.initialValues,
    );

    const isDirty = () => {
        return (
            formikInitialValues.name !== formik.values.name ||
            formikInitialValues.slug !== formik.values.slug ||
            formikInitialValues.description !== formik.values.description
        );
    };

    useEffect(() => {
        if (isEditMode && pocId) {
            PocService.getPoc(pocId).then((response) => {
                formik.setValues({
                    name: response.data.name,
                    slug: response.data.slug,
                    description: response.data.description,
                });
                setFormikInitialValues({
                    name: response.data.name,
                    slug: response.data.slug,
                    description: response.data.description,
                });
            });
        }
    }, [pocId, isEditMode, formik.setValues]);

    useEffect(() => {
        const slug = formik.values.name
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/[^a-z0-9-]/g, "")
            .replace(/-+/g, "-");
        formik.setFieldValue("slug", slug, false);
    }, [formik.values.name, formik.setFieldValue]);

    useEffect(() => {
        setIsCreateFormDirty?.(isDirty());
    }, [formik.values.name, formik.values.slug, formik.values.description]);

    useFormPrompt(isDirty());
    return (
        <>
            <Card
                className="poc-card create-poc-form"
                data-test="CreatePoCForm">
                <CardBody>
                    {isEditMode ? null : (
                        <CardTitle tag="h5">
                            {t("createPoC.createNewPoC")}
                        </CardTitle>
                    )}
                    <Form onSubmit={formik.handleSubmit}>
                        <FormGroup>
                            <Label for="name">{t("createPoC.name")} *</Label>
                            <Input
                                type="text"
                                name="name"
                                id="name"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setSubmitError("");
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                invalid={
                                    formik.touched.name && !!formik.errors.name
                                }
                            />
                            <FormFeedback>{formik.errors.name}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="slug">{t("createPoC.slug")} *</Label>
                            <Input
                                type="text"
                                name="slug"
                                id="slug"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                    setSubmitError("");
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.slug}
                                invalid={
                                    formik.touched.slug && !!formik.errors.slug
                                }
                            />
                            <FormFeedback>{formik.errors.slug}</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="description">
                                {t("createPoC.description")} *
                            </Label>
                            <Input
                                type="textarea"
                                name="description"
                                id="description"
                                onChange={(e) => {
                                    formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                value={formik.values.description}
                                invalid={
                                    formik.touched.description &&
                                    !!formik.errors.description
                                }
                            />
                            <FormFeedback>
                                {formik.errors.description}
                            </FormFeedback>
                        </FormGroup>

                        {submitError && (
                            <div className="text-danger">{submitError}</div>
                        )}
                        <Button
                            type="submit"
                            color="primary"
                            className="submit-button">
                            {t("createPoC.saveAndContinue")}
                        </Button>
                        {pocId === undefined ? (
                            <LeavePageConfirmationDialog
                                hasChanges={isDirty()}
                            />
                        ) : null}
                    </Form>
                </CardBody>
            </Card>
        </>
    );
};

export default CreatePoC;
