import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Layout from "../layouts/Layout";
import CenteredLoader from "../components/CenteredLoader";
import TagService from "../services/TagService";
import FilterGrid from "../components/PocsFilterGrid";
import { useTranslation } from "react-i18next";
import { TAGS_PAGE } from "utils/GlobalConstants";

function Tag() {
    const { tagId } = useParams<{ tagId: string }>();
    const tagIdNumeric: number = tagId ? parseInt(tagId) : 0;

    const [tag, setTag] = useState<any | null>(null);
    const [isGetTagInProgress, setIsGetTagInProgress] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        loadTag();
    }, []);

    const loadTag = () => {
        if (tagIdNumeric !== 0) {
            TagService.getTag(tagIdNumeric)
                .then((res) => {
                    setTag(res.data);
                    setIsGetTagInProgress(false);
                })
                .catch((e) => {
                    setIsGetTagInProgress(false);
                    if (e.response && e.response.status === 404) {
                        // Handle the 404 error with a user-friendly message
                        setError(t("error.pageNotFound"));
                    } else {
                        // Handle other errors
                        setError(t("error.fetchError"));
                        console.log(e);
                    }
                });
        }
    };

    return (
        <Layout>
            {isGetTagInProgress && <CenteredLoader />}
            {error != null && (
                // Display the error message for 404 or other errors
                <div>
                    <h1>{t("error.error")}</h1>
                    <p>{error}</p>
                </div>
            )}
            {tag !== null && (
                <>
                    <h2 className="mt-5">
                        {t("tags.poCsTaggedWith")} # {tag.name}:
                    </h2>
                    <br />
                    <FilterGrid
                        filterByTagId={tag.id}
                        accessedFrom={TAGS_PAGE}
                    />
                </>
            )}
        </Layout>
    );
}

export default Tag;
