import React, { useId, useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { Tooltip } from "reactstrap";
import "../styles/CreatePoCLink.scss";
import UserRole from "../auth/enums/UserRole";
import AuthService from "../auth/AuthService";

interface ProtectedNavigationLinkProps {
    linkName: string | string[];
    loginInTooltip: string | string[];
    authorizationTooltip: string | string[];
    allowedRoles: UserRole[];
    httpLink: string;
}

const ProtectedNavigationLink: React.FC<ProtectedNavigationLinkProps> = ({
    linkName,
    loginInTooltip,
    authorizationTooltip,
    allowedRoles,
    httpLink,
}) => {
    const id = useId().replaceAll(":", "id");
    const hasRequiredRole = AuthService.hasAnyOfRoles(allowedRoles);

    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

    const getTooltipMessage = () => {
        if (!AuthService.isAuthenticated()) {
            return loginInTooltip;
        }
        if (!hasRequiredRole) {
            return authorizationTooltip;
        }
        return null;
    };

    return (
        <div onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip} id={id}>
            <RouterNavLink
                to={
                    AuthService.isAuthenticated() && hasRequiredRole
                        ? httpLink
                        : "#"
                }
                className={`nav-link ${
                    !AuthService.isAuthenticated() || !hasRequiredRole
                        ? "disabled-link"
                        : ""
                }`}
                id={`create-poc-link-${id}`}>
                {linkName}
            </RouterNavLink>
            {getTooltipMessage() !== null && (
                <Tooltip
                    placement="bottom"
                    isOpen={tooltipOpen}
                    target={id}
                    toggle={toggleTooltip}>
                    {getTooltipMessage()}
                </Tooltip>
            )}
        </div>
    );
};

export default ProtectedNavigationLink;
