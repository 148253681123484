import React from "react";
import { useBlocker } from "react-router-dom";
import { Button, Card, CardFooter, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import "../styles/ModalDialogFooter.scss";

interface LeavePageConfirmationDialogProps {
    hasChanges: boolean;
}

const LeavePageConfirmationDialog: React.FC<
    LeavePageConfirmationDialogProps
> = (props: LeavePageConfirmationDialogProps) => {
    const { t } = useTranslation();

    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            props.hasChanges &&
            currentLocation.pathname !== nextLocation.pathname,
    );

    const isBlocked: boolean = blocker.state === "blocked";

    return (
        <Modal isOpen={isBlocked} className="poc-modal">
            <Card className="poc-card">
                <div className="modal-header">
                    <h5 className="modal-title">
                        You have unsaved changes. Do you wish to proceed and
                        lose your data?
                    </h5>
                </div>
                <CardFooter className="modal-poc-footer">
                    <Button color="danger" onClick={() => blocker.proceed?.()}>
                        {t("yes")}
                    </Button>
                    <Button color="secondary" onClick={() => blocker.reset?.()}>
                        {t("no")}
                    </Button>
                </CardFooter>
            </Card>
        </Modal>
    );
};

export default LeavePageConfirmationDialog;
