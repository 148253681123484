import { useCallback, useEffect, useState } from "react";
import PocCard from "components/PocCard";
import "styles/PocsGrid.scss";
import PocsService from "services/PocsService";
import PaginationSection from "components/PaginationSection";
import CenteredLoader from "components/CenteredLoader";
import { PublishedFilterEnum } from "components/filters/PublishedFilter";
import DeletePoc from "./DeletePoc";
import UserRole from "auth/enums/UserRole";
import AuthService from "auth/AuthService";
import EditPoC from "components/EditPoc";
import PublishedPocsService from "../services/PublishedPocsService";
import placeholder from "../resources/placeholder.jpg";
import { useTranslation } from "react-i18next";

interface PocsGridProps {
    published: PublishedFilterEnum;
    currentPage: number;
    setCurrentPage: (arg: number) => void;
    filterByAuthorId?: number;
    filterByTagId?: number;
    filterByPocName?: string;
    sortField?: string;
    sortOrder?: string;
}

const Grid = ({
    published,
    currentPage,
    setCurrentPage,
    filterByAuthorId,
    filterByTagId,
    filterByPocName,
    sortField,
    sortOrder,
}: PocsGridProps) => {
    const [pocs, setPocs] = useState<any[] | null>(null);
    const [isGetPocsApiInProgress, setIsGetPocsApiInProgress] = useState(false);
    const [pageSize] = useState(8);
    const [totalPocs, setTotalPocs] = useState<number | null>(null);
    const isAuthenticated = AuthService.isAuthenticated();
    const { t } = useTranslation();

    const getTotalPageCount = (): number => {
        if (totalPocs !== null) {
            return Math.ceil(totalPocs / pageSize);
        } else return 0;
    };

    const loadPocs = useCallback((): void => {
        setIsGetPocsApiInProgress(true);
        isAuthenticated
            ? PocsService.getPocs(
                  currentPage,
                  pageSize,
                  published,
                  filterByPocName,
                  filterByAuthorId,
                  filterByTagId,
                  sortField,
                  sortOrder,
              )
                  .then((res) => {
                      setPocs(res.items);
                      setTotalPocs(res.totalCount);
                      setIsGetPocsApiInProgress(false);
                  })
                  .catch((e) => {
                      console.log(e);
                  })
            : PublishedPocsService.getFilteredPublishedPocs(
                  currentPage,
                  pageSize,
                  filterByPocName,
                  filterByAuthorId,
                  filterByTagId,
                  sortField,
                  sortOrder,
              )
                  .then((res) => {
                      setPocs(res.items);
                      setTotalPocs(res.totalCount);
                      setIsGetPocsApiInProgress(false);
                  })
                  .catch((e) => {
                      console.log(e);
                  });
    }, [
        currentPage,
        pageSize,
        published,
        filterByAuthorId,
        filterByTagId,
        filterByPocName,
        sortField,
        sortOrder,
    ]);

    useEffect(() => {
        loadPocs();
    }, [loadPocs]);

    const handleDeleteSuccess = (id: number) => {
        if (pocs && pocs.length) {
            setPocs(
                pocs.filter((poc: any) => {
                    return poc.id !== id ? poc : null;
                }),
            );
        } else {
            loadPocs();
        }
    };
    const isAdmin = AuthService.hasRole(UserRole.ADMIN);
    // abort implementation
    // useEffect(() => {
    //     const abort = new AbortController();
    //     loadPocs(abort.signal);
    //     return () => abort.abort();
    // }, [loadPocs]);

    return (
        <div>
            {isGetPocsApiInProgress && <CenteredLoader />}
            {!isGetPocsApiInProgress && pocs && pocs.length > 0 ? (
                <div className="pocs-grid">
                    {pocs.map((poc, pocIndex) => (
                        <div key={`poc-${pocIndex}`}>
                            <PocCard
                                id={poc.id}
                                published={
                                    poc.published
                                        ? PublishedFilterEnum.PUBLISHED
                                        : PublishedFilterEnum.DRAFT
                                }
                                name={poc.name}
                                slug={poc.slug}
                                description={poc.description}
                                authors={poc.authors.map(
                                    (a: any) => `${a.firstName} ${a.lastName}`,
                                )}
                                tags={poc.tags.map((t: any) => {
                                    return t.name;
                                })}
                                isPublished={poc.published}
                                deleteButton={
                                    <DeletePoc
                                        pocId={poc.id}
                                        externalIds={poc.authors.map(
                                            (a: any) => a.externalUserId,
                                        )}
                                        isAdmin={isAdmin}
                                        onDeleteSuccess={handleDeleteSuccess}
                                    />
                                }
                                editButton={
                                    <EditPoC
                                        pocId={poc.id}
                                        externalIds={poc.authors.map(
                                            (a: any) => a.externalUserId,
                                        )}
                                        isAdmin={isAdmin}
                                    />
                                }
                                coverImageUrl={poc.coverImageUrl || placeholder}
                            />
                        </div>
                    ))}
                </div>
            ) : !isGetPocsApiInProgress && pocs && pocs.length === 0 ? (
                <div className="no-results-found">
                    {t("grid.noResultsFound")}
                </div>
            ) : null}
            <div className="mt-3 d-flex justify-content-center">
                {!isGetPocsApiInProgress &&
                    pocs &&
                    pocs.length > 0 &&
                    totalPocs !== null && (
                        <PaginationSection
                            isLoading={isGetPocsApiInProgress}
                            currentPage={currentPage}
                            totalPageCount={getTotalPageCount()}
                            setCurrentPage={setCurrentPage}
                        />
                    )}
            </div>
        </div>
    );
};

export default Grid;
