import Keycloak from "keycloak-js";
import UserRole from "./enums/UserRole";

interface AuthServiceType {
    kc: Keycloak | null;
    isKcInitialized: boolean;
    ssoInit: Function;
    authenticate: Function;
    isAuthenticated: Function;
    getUserId: Function;
    getUserNames: Function;
    getToken: Function;
    hasAnyOfRoles: Function;
    hasRole: Function;
    doLogout: Function;
}

const AuthService: AuthServiceType = {
    kc: null,

    isKcInitialized: false,

    ssoInit: async () => {
        if (AuthService.kc === null) {
            AuthService.kc = new Keycloak({
                url: process.env.REACT_APP_KEYCLOAK_POC_PLATFORM_BASE_URL,
                realm: "poc-platform",
                clientId: "poc-platform",
            });

            AuthService.isKcInitialized = true;

            try {
                return await AuthService.kc.init({
                    onLoad: "check-sso",
                });
            } catch (error) {
                console.error(
                    "Failed to initialize the keycloak adapter:",
                    error,
                )
            }
        }
    },
    authenticate: async (authenticatedCallback: Function) => {
        if (AuthService.kc === null) {
            AuthService.kc = new Keycloak({
                url: process.env.REACT_APP_KEYCLOAK_POC_PLATFORM_BASE_URL,
                realm: "poc-platform",
                clientId: "poc-platform",
            });
        }
        if (AuthService.isKcInitialized === false) {

            AuthService.kc.init({
                onLoad: "login-required",
            }).then((authenticated) => {
                if (authenticated) {
                    authenticatedCallback();
                }
            }).catch((error) =>
                console.error(
                    "Failed to initialize the keycloak adapter:",
                    error,
                )
            );
            AuthService.isKcInitialized = true;
        } else {
            if (!AuthService.kc.authenticated) {
                try {
                    await AuthService.kc.login();
                } catch (error) {
                    console.error(
                        "Failed to initialize the keycloak adapter:",
                        error,
                    );
                }
            } else {
                authenticatedCallback();
            }
        }
    },

    isAuthenticated: () => {
        return AuthService.kc?.authenticated;
    },

    getUserNames: () => {
        let names = "";
        if (AuthService.kc?.authenticated) {
            let firstName = AuthService.kc.tokenParsed?.given_name;
            let lastName = AuthService.kc.tokenParsed?.family_name;
            names = `${firstName} ${lastName}`;
        }
        return names;
    },
    getUserId: () => {
        let id: string | undefined = "";
        if (AuthService.kc?.tokenParsed) {
            id = AuthService.kc.tokenParsed.sub;
        }
        return id;
    },

    getToken: async () => {
        try {
            await AuthService.kc?.updateToken(30);
        } catch (error) {
            console.error(error);
        }

        return AuthService.kc?.token;
    },

    hasAnyOfRoles: (roles: UserRole[]) => {
        return roles.find((role) => {
            return AuthService.kc?.hasRealmRole(role);
        });
    },

    hasRole: (role: string) => {
        return AuthService.kc?.hasRealmRole(role);
    },

    doLogout: () => {
        AuthService.kc?.logout({ redirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URL }).catch((error) => {
            console.error("User logout error ", error);
        });
    }
};

export default AuthService;
