import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

interface PaginationSectionProps {
    currentPage: number;
    totalPageCount: number;
    isLoading: boolean;
    setCurrentPage: (newPage: number) => void;
}

const PaginationSection: React.FC<PaginationSectionProps> = ({
    currentPage,
    totalPageCount,
    isLoading,
    setCurrentPage,
}: PaginationSectionProps) => {
    return (
        <div className="pagination-section">
            <Pagination>
                <PaginationItem disabled={currentPage == 0 || isLoading}>
                    <PaginationLink
                        previous
                        onClick={() => {
                            setCurrentPage(currentPage - 1);
                        }}
                    />
                </PaginationItem>
                <PaginationItem disabled={(currentPage == totalPageCount - 1) || isLoading}>
                    <PaginationLink
                        next
                        onClick={() => {
                            setCurrentPage(currentPage + 1);
                        }}
                    />
                </PaginationItem>
            </Pagination>
        </div >
    );
};

export default PaginationSection;
