import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    CloseButton,
    Input,
    InputGroup,
    InputGroupText,
    Modal,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import AddAndRemoveLists from "./AddAndRemoveLists";
import toast from "react-hot-toast";
import TagService from "../services/TagService";

interface TagsModalProps {
    pocId: number;
    toggleModal: () => void;
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const TagsModal: React.FC<TagsModalProps> = (props: TagsModalProps) => {
    const [allTags, setAllTags] = useState(Array<any>);
    const [tagsAdded, setTagsAdded] = useState(Array<any>);
    const [tagName, setTagName] = useState("");

    const { t } = useTranslation();

    useEffect(() => {
        let tagsAddedTemp: Array<any>;

        TagService.getPocTags(props.pocId)
            .then((res) => {
                tagsAddedTemp = res.data.map((x: any) => ({
                    id: x.tagAssociationId,
                    name: x.name,
                }));
                setTagsAdded(tagsAddedTemp);
            })
            .then(() => {
                TagService.getAllTags().then((res) => {
                    setAllTags(
                        res.data.filter((tag: any) => {
                            return !tagsAddedTemp.some(
                                (addedTag) => addedTag.id === tag.id,
                            );
                        }),
                    );
                });
            });
    }, [props.pocId]);

    const addTag = (tagId: number, tagName: string | null) => {
        TagService.associateTag(props.pocId, tagId)
            .then(() => {
                toast.success(
                    t("result.successfullyAdded", {
                        label: tagName,
                    }),
                );
            })
            .catch(() => {
                toast.error(t("tags.addTagError"));
            });
    };

    const removeTag = (tagId: number, tagName: string | null) => {
        TagService.removeTagAssociation(props.pocId, tagId)
            .then(() => {
                toast.success(
                    t("result.successfullyRemoved", {
                        label: tagName,
                    }),
                );
            })
            .catch(() => {
                toast.error(t("tags.removeTagError"));
            });
    };

    const handleCreate = () => {
        TagService.createTag(tagName)
            .then((res) => {
                setAllTags([res.data, ...allTags]);

                toast.success(
                    t("result.successfullyCreated", {
                        label: tagName,
                    }),
                );
                setTagName("");
            })
            .catch((err) => {
                if (err.response.status === 409) {
                    toast.error(
                        t("tags.errorCreatingTag", {
                            label: tagName,
                        }),
                    );
                }
            });
    };

    const handleKeyPress = (event: any) => {
        if (event.key === "Enter") {
            handleCreate();
        }
    };

    return (
        <Modal
            isOpen={props.isModalOpen}
            toggle={props.toggleModal}
            className="poc-modal modal-lg">
            <Card className="poc-card" style={{ maxWidth: "100%" }}>
                <div className="modal-header">
                    <CardTitle className="modal-title">
                        {t("tags.addOrRemove")}
                    </CardTitle>
                    <CloseButton onClick={props.toggleModal} />
                </div>
                <CardBody
                    style={{ minHeight: "400px" }}
                    className="table-responsive">
                    <div>
                        <h2>{t("tags.availableTags")}</h2>
                        <InputGroup className="tagsCreate">
                            <Input
                                placeholder={t("tags.enterTagName")}
                                value={tagName}
                                onKeyDown={handleKeyPress}
                                onChange={(e) =>
                                    setTagName(e.target.value.toString().trim())
                                }
                            />
                            <InputGroupText>
                                <Button
                                    color={"primary"}
                                    disabled={tagName.length < 1}
                                    onClick={handleCreate}>
                                    {t("result.create")}
                                </Button>
                            </InputGroupText>
                        </InputGroup>

                        <AddAndRemoveLists
                            addItem={addTag}
                            removeItem={removeTag}
                            getListItemLabel={(item) => item.name}
                            getListItemId={(item) => item.id}
                            itemsFound={allTags}
                            setItemsFound={setAllTags}
                            itemsAdded={tagsAdded}
                            setItemsAdded={setTagsAdded}
                            itemsAddedLabel={t("tags.associatedTags")}
                        />
                    </div>
                </CardBody>
            </Card>
        </Modal>
    );
};

export default TagsModal;
