import React, { useState } from "react";
import { Modal, Button, Card, CloseButton, CardFooter } from "reactstrap";
import { Trash3 } from "react-bootstrap-icons";
import CoverImageService from "../services/CoverImageService";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import "../styles/ModalDialogFooter.scss";
interface DeleteCoverImageProps {
    pocId: number;
    refreshCoverImage: () => void;
    className: string;
}
const DeleteCoverImage: React.FC<DeleteCoverImageProps> = ({
    pocId,
    refreshCoverImage,
    className,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { t } = useTranslation();
    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const handleDelete = async () => {
        try {
            await CoverImageService.deleteCoverImage(pocId);
            toast.success(t("deletePocImage.success"));
            refreshCoverImage();
            toggleModal();
        } catch (error) {
            console.error("Failed to delete cover image:", error);
            toast.error("Failed to delete cover image.");
        }
    };

    return (
        <>
            <Button
                color="danger"
                onClick={toggleModal}
                className="delete-cover-image-btn">
                <Trash3 />
            </Button>

            <Modal
                isOpen={isModalOpen}
                toggle={toggleModal}
                className="poc-modal">
                <Card className="poc-card">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {t("deletePocImage.confirmDeleteDialog")}
                        </h5>
                        <CloseButton
                            onClick={toggleModal}
                            className="ml-auto"
                        />
                    </div>
                    <CardFooter className="modal-poc-footer">
                        <Button color="danger" onClick={handleDelete}>
                            {t("deletePocImage.delete")}
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>
                            {t("deletePocImage.cancel")}
                        </Button>
                    </CardFooter>
                </Card>
            </Modal>
        </>
    );
};

export default DeleteCoverImage;
