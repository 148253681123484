import { useEffect } from "react";

export function useFormPrompt(hasUnsavedChanges: boolean) {
    const onBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        return (event.returnValue = "");
    };

    useEffect(() => {
        if (!hasUnsavedChanges) return;

        window.addEventListener("beforeunload", onBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, [hasUnsavedChanges]);
}
