import { Col, Row } from "reactstrap";
import Layout from "../layouts/Layout";
import { useParams, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import PublishedPocsService from "../services/PublishedPocsService";
import CenteredLoader from "../components/CenteredLoader";
import MdxContent from "../components/MdxContent";
import AuthorAvatar from "../components/AuthorAvatar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PocService from "services/PocService";
import placeholder from "../resources/placeholder.jpg";
import UserRole from "auth/enums/UserRole";
import AuthService from "auth/AuthService";
import EditPoc from "components/EditPoc";
import DeletePoc from "components/DeletePoc";
import "../styles/PublishedPoc.scss";
import { canEdit } from "utils/functions";
interface Author {
    id: number;
    firstName: string;
    lastName: string;
    pictureUrl: string;
    externalUserId: string;
}

interface Tag {
    id: number;
    name: string;
}

function PublishedPoc() {
    const params = useParams();
    const { slug } = params;
    const id = params.id ? parseInt(params.id, 10) : undefined;
    const [poc, setPoc] = useState<any | null>(null);
    const [isGetPocApiInProgress, setIsGetPocApiInProgress] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        loadPoc();
    }, [slug, id]);

    const loadPoc = () => {
        setIsGetPocApiInProgress(true);

        const fetchById = () => {
            if (id) {
                PocService.getPoc(id)
                    .then((res) => {
                        setPoc(res.data);
                    })
                    .catch((e) => {
                        setError(t("error.fetchError"));
                        console.error(e);
                    })
                    .finally(() => {
                        setIsGetPocApiInProgress(false);
                    });
            }
        };

        if (slug) {
            PublishedPocsService.getPublishedPoc(slug)
                .then((res) => {
                    setPoc(res.data);
                })
                .catch((e) => {
                    if (e.response && e.response.status === 404) {
                        fetchById();
                    } else {
                        setError(t("error.fetchError"));
                        console.error(e);
                    }
                })
                .finally(() => {
                    setIsGetPocApiInProgress(false);
                });
        } else {
            fetchById();
        }
    };

    const isAdmin = AuthService.hasRole(UserRole.ADMIN);
    const handleDeleteSuccess = () => {
        navigate("/");
    };

    return (
        <Layout>
            {isGetPocApiInProgress && <CenteredLoader />}
            {poc && (
                <div className="publishedPoc">
                    <Row>
                        <Col lg={9} md={12}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <h2 className="poc-title">{poc.name}</h2>
                                <div className="d-flex align-items-center">
                                    <EditPoc
                                        pocId={poc.id}
                                        externalIds={poc.authors.map(
                                            (a: any) => a.externalUserId,
                                        )}
                                        isAdmin={isAdmin}
                                    />
                                    <div className="ms-2">
                                        <DeletePoc
                                            pocId={poc.id}
                                            externalIds={poc.authors.map(
                                                (a: any) => a.externalUserId,
                                            )}
                                            isAdmin={isAdmin}
                                            onDeleteSuccess={
                                                handleDeleteSuccess
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="poc-cover-wrapper">
                                {!poc.published && (
                                    <div className="poc-draft-label">
                                        {t("publishedPoc.draft")}
                                    </div>
                                )}
                                <img
                                    className="poc-cover-image"
                                    src={poc.coverImageUrl || placeholder}
                                    width={"100%"}
                                    alt={poc.name || "PoC Image"}
                                />
                            </div>
                            <p className="poc-description">{poc.description}</p>
                            <hr />
                            <div className="poc-article">
                                {poc?.published ||
                                canEdit(
                                    poc.authors.map(
                                        (author: Author) =>
                                            author.externalUserId,
                                    ),
                                ) ? (
                                    <MdxContent content={poc.articleText} />
                                ) : null}
                            </div>
                        </Col>
                        <Col lg={3} md={12}>
                            <div className="d-lg-none mt-5">
                                <hr />
                            </div>
                            <h5>{t("publishedPoc.createdDate")}:</h5>
                            <div>
                                {poc?.published ||
                                canEdit(
                                    poc.authors.map(
                                        (author: Author) =>
                                            author.externalUserId,
                                    ),
                                ) ? (
                                    poc.createdAt ? (
                                        <div>
                                            {poc.createdAt
                                                .slice(0, 19)
                                                .replace("T", " ")}
                                        </div>
                                    ) : (
                                        <p>{t("publishedPoc.notAvailable")}</p>
                                    )
                                ) : (
                                    <p>{t("publishedPoc.notAvailable")}</p>
                                )}
                            </div>
                            <hr />
                            <h5>{t("publishedPoc.lastUpdateDate")}:</h5>
                            <div>
                                {poc?.published ||
                                canEdit(
                                    poc.authors.map(
                                        (author: Author) =>
                                            author.externalUserId,
                                    ),
                                ) ? (
                                    poc.updatedAt ? (
                                        <div>
                                            {poc.updatedAt
                                                .slice(0, 19)
                                                .replace("T", " ")}
                                        </div>
                                    ) : (
                                        <p>{t("publishedPoc.notAvailable")}</p>
                                    )
                                ) : (
                                    <p>{t("publishedPoc.notAvailable")}</p>
                                )}
                            </div>
                            <hr />
                            <h5>{t("publishedPoc.caseStudy")}:</h5>
                            <div>
                                {poc?.published ||
                                canEdit(
                                    poc.authors.map(
                                        (author: Author) =>
                                            author.externalUserId,
                                    ),
                                ) ? (
                                    poc.caseStudyLink ? (
                                        <a
                                            href={poc.caseStudyLink}
                                            rel="nofollow">
                                            {poc.caseStudyLink}
                                        </a>
                                    ) : (
                                        <p>{t("publishedPoc.notAvailable")}</p>
                                    )
                                ) : (
                                    <p>{t("publishedPoc.notAvailable")}</p>
                                )}
                            </div>
                            <hr />
                            <div>
                                <h5 className={"mt-4"}>
                                    {t("publishedPoc.sourceCode")}:
                                </h5>
                                {poc?.published ||
                                canEdit(
                                    poc.authors.map(
                                        (author: Author) =>
                                            author.externalUserId,
                                    ),
                                ) ? (
                                    poc.sourceCodeLink ? (
                                        <a
                                            href={poc.sourceCodeLink}
                                            rel="nofollow">
                                            {poc.sourceCodeLink}
                                        </a>
                                    ) : (
                                        <p>{t("publishedPoc.notAvailable")}</p>
                                    )
                                ) : (
                                    <p>{t("publishedPoc.notAvailable")}</p>
                                )}
                            </div>

                            <hr />
                            <div>
                                <h5>{t("publishedPoc.authors")}:</h5>
                                {poc.authors?.length > 0 ? (
                                    <div className="authors-container">
                                        {poc.authors.map((author: Author) => (
                                            <AuthorAvatar
                                                key={author.id}
                                                authorId={author.id.toString()}
                                                name={`${author.firstName} ${author.lastName}`}
                                                photourl={author.pictureUrl}
                                                pixelWidth={50}
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    <p>{t("publishedPoc.notAvailable")}</p>
                                )}
                            </div>
                            <hr />
                            <div>
                                <h5>{t("publishedPoc.tags")}:</h5>
                                {poc.tags?.length > 0 ? (
                                    <div className="tags-container">
                                        {poc.tags.map((tag: Tag) => (
                                            <Link
                                                key={tag.id}
                                                to={`/tag/${tag.id}`}
                                                className="tag-link">
                                                #{tag.name}
                                            </Link>
                                        ))}
                                    </div>
                                ) : (
                                    <p>{t("publishedPoc.notAvailable")}</p>
                                )}
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </Layout>
    );
}

export default PublishedPoc;
