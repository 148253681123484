import AuthService from "auth/AuthService";
import pocApiAxios, { IPaginatedResponse } from "./PocApiAxiosBase";

const SuggestionsService = {
    async getSuggestions(
        page: number,
        pageSize: number,
        sort?: string,
        order?: string,
        filterByPocName?: string,
    ): Promise<IPaginatedResponse<any>> {
        const authToken = await AuthService.getToken();
        let suggestionsUrl = `/suggested-pocs?page=${page}&size=${pageSize}`;

        if (sort) {
            suggestionsUrl += `&sort=${sort}`;
        }
        if (order) {
            suggestionsUrl += `&order=${order}`;
        }
        if (filterByPocName !== undefined) {
            suggestionsUrl += `&filterByPocName=${encodeURIComponent(
                filterByPocName,
            )}`;
        }
        return new Promise<IPaginatedResponse<any>>((resolve, reject) => {
            pocApiAxios
                .get<Array<any>>(suggestionsUrl, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                })
                .then((res) => {
                    const paginatedResponse: IPaginatedResponse = {
                        totalCount: res.headers["x-total-count"],
                        items: res.data,
                    };
                    resolve(paginatedResponse);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default SuggestionsService;
