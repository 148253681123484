import { PencilSquare } from "react-bootstrap-icons";
import { canEdit } from "utils/functions";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";

export default function EditPoc({
    pocId,
    externalIds,
    isAdmin,
}: {
    pocId: number;
    externalIds: string[];
    isAdmin: boolean;
}) {
    const isAllowed: boolean = canEdit(externalIds) || isAdmin;
    return (
        <>
            {isAllowed && (
                <Link to={`/pocs/${pocId}/edit-poc`}>
                    <Button>
                        <PencilSquare size={30} color="#6fa8fe" />
                    </Button>
                </Link>
            )}
        </>
    );
}
