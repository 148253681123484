import axios from "axios";

export interface IPaginatedResponse<T = any> {
    totalCount: number;
    items: Array<T>;
}

const pocApiAxios = axios.create({
    baseURL: process.env.REACT_APP_POC_PLATFORM_API_BASE_URL,
    headers: {
        "Content-type": "application/json",
    },
});

export default pocApiAxios;
