import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";
import Layout from "../layouts/Layout";
import CenteredLoader from "../components/CenteredLoader";
import SuggestionService from "../services/SuggestionService";
import { useTranslation } from "react-i18next";
import "../styles/PublishedPoc.scss";
import DeleteSuggestion from "components/DeleteSuggestion";
import EditSuggestion from "components/EditSuggestion";
import AuthService from "auth/AuthService";
import UserRole from "auth/enums/UserRole";
import VoteSuggestion from "components/VoteSuggestion";
interface Suggester {
    id: number;
    externalUserId: string;
    firstName: string;
    lastName: string;
    shortBio?: string;
    pictureUrl?: string;
}
interface Voter {
    externalUserID: string;
}
interface Suggestion {
    id: number;
    name: string;
    description: string;
    status: string;
    suggester: Suggester;
    dateCreated: string;
    lastUpdated: string;
    totalVotes: number;
    deleteButton?: React.ReactNode;
    voters: Voter[];
}

const SuggestedPoCDetails: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [suggestion, setSuggestion] = useState<Suggestion | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [hasVoted, setHasVoted] = useState<boolean>(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchSuggestionDetails(parseInt(id));
        }
    }, [id]);

    const fetchSuggestionDetails = async (id: number) => {
        setIsLoading(true);
        try {
            const response = await SuggestionService.getSuggestionById(id);
            setSuggestion(response.data);
            const userVoted = response.data.voters.some(
                (voter: Voter) =>
                    voter.externalUserID === AuthService.getUserId(),
            );
            setHasVoted(userVoted);
        } catch (error) {
            console.error("Failed to fetch suggestion details", error);
        } finally {
            setIsLoading(false);
        }
    };
    const handleVoteChange = async () => {
        try {
            await SuggestionService.voteForSuggestion(suggestion!.id);

            setSuggestion((prevSuggestion) => {
                if (!prevSuggestion) return null;
                let updatedVoters;
                if (hasVoted) {
                    updatedVoters = prevSuggestion.voters.filter(
                        (voter) =>
                            voter.externalUserID !== AuthService.getUserId(),
                    );
                } else {
                    updatedVoters = [
                        ...prevSuggestion.voters,
                        {
                            externalUserID: AuthService.getUserId(),
                        },
                    ];
                }
                return {
                    ...prevSuggestion,
                    voters: updatedVoters,
                };
            });
            setHasVoted(!hasVoted);
        } catch (error) {
            console.error("Error voting for suggestion:", error);
        }
    };
    const isAdmin = AuthService.hasRole(UserRole.ADMIN);
    const canEdit =
        suggestion?.suggester.externalUserId === AuthService.getUserId();
    const handleDeleteSuccess = () => {
        navigate("/");
    };
    return (
        <Layout>
            {isLoading && <CenteredLoader />}
            {suggestion && (
                <div className="publishedPoc">
                    <Row>
                        <Col lg={9} md={12}>
                            <div className="d-flex justify-content-between align-items-center mb-2">
                                <h2 className="poc-title">{suggestion.name}</h2>
                                {(isAdmin || canEdit) && (
                                    <div className="d-flex align-items-center">
                                        <EditSuggestion
                                            suggestionId={suggestion.id}
                                        />
                                        <div className="ms-2">
                                            <DeleteSuggestion
                                                suggestionId={suggestion.id}
                                                onDeleteSuccess={
                                                    handleDeleteSuccess
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <hr />
                            <p className="poc-description">
                                {suggestion.description}
                            </p>
                        </Col>
                        <Col lg={3} md={4}>
                            <div className="mt-3">
                                <h5>{t("suggestionDetails.suggester")}:</h5>
                                <p>{`${suggestion.suggester.firstName} ${suggestion.suggester.lastName}`}</p>
                            </div>
                            <hr />
                            <div>
                                <h5>{t("publishedPoc.createdDate")}:</h5>
                                <p>
                                    {new Date(
                                        suggestion.dateCreated,
                                    ).toLocaleString()}
                                </p>
                            </div>
                            <hr />
                            <div>
                                <h5>{t("publishedPoc.lastUpdateDate")}:</h5>
                                <p>
                                    {new Date(
                                        suggestion.lastUpdated,
                                    ).toLocaleString()}
                                </p>
                            </div>
                            <hr />
                            <div>
                                <h5>{t("suggestionCard.votes")}:</h5>
                                <p>{suggestion.voters.length}</p>
                                <VoteSuggestion
                                    suggestedPocId={suggestion.id}
                                    hasVoted={hasVoted}
                                    onVoteChange={handleVoteChange}
                                />
                            </div>
                        </Col>
                    </Row>
                </div>
            )}
        </Layout>
    );
};

export default SuggestedPoCDetails;
