import { useEffect, useRef, useState } from "react";

import "./App.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import PublishedPoc from "./pages/PublishedPoc";
import "highlight.js/styles/a11y-dark.css";
import "katex/dist/katex.min.css";
import Author from "./pages/Author";
import Tag from "./pages/Tag";
import ProtectedPageExample from "./pages/ProtectedExample";
import AuthGuard from "./auth/AuthGuard";
import UserRole from "./auth/enums/UserRole";
import AuthService from "./auth/AuthService";
import { Notification } from "./components/Notification";
import EditPoC from "./pages/EditPoc";
import CreateFullPoc from "pages/CreateFullPoc";
import SuggestPoC from "pages/SuggestPoC";
import SuggestedPoCDetails from "pages/SuggestedPoCDetails";
import AccessWrapper from "components/AccessWrapper";
function App() {
    const isRunOnceEffectExecuted = useRef(false);
    const [ssoInitCompleted, setSSOInitCompleted] = useState<Boolean>(false);

    useEffect(() => {
        if (!isRunOnceEffectExecuted.current) {
            AuthService.ssoInit().then((a: any) => {
                console.log("resolved", a);
                setSSOInitCompleted(true);
            });
            isRunOnceEffectExecuted.current = true;
        }
    }, []);

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home />,
        },
        {
            path: "/poc/:slug",
            element: <PublishedPoc />,
        },
        {
            path: "/author/:authorId",
            element: <Author />,
        },
        {
            path: "/tag/:tagId",
            element: <Tag />,
        },
        {
            path: "/pocs/:id",
            element: (
                <AuthGuard allowedRoles={[UserRole.ADMIN, UserRole.EMPLOYEE]}>
                    <PublishedPoc />
                </AuthGuard>
            ),
        },
        {
            path: "/suggested-pocs/:id",
            element: (
                <AuthGuard allowedRoles={[UserRole.ADMIN, UserRole.EMPLOYEE]}>
                    <SuggestedPoCDetails />
                </AuthGuard>
            ),
        },
        {
            path: "/suggested-pocs/:id/edit-suggestion",
            element: (
                <AuthGuard allowedRoles={[UserRole.ADMIN, UserRole.EMPLOYEE]}>
                    <AccessWrapper>
                        <SuggestPoC />
                    </AccessWrapper>
                </AuthGuard>
            ),
        },
        {
            path: "/protected-page",
            element: (
                <AuthGuard allowedRoles={[UserRole.ADMIN]}>
                    <ProtectedPageExample />
                </AuthGuard>
            ),
        },
        {
            path: "/suggest-poc",
            element: (
                <AuthGuard allowedRoles={[UserRole.ADMIN, UserRole.EMPLOYEE]}>
                    <SuggestPoC />
                </AuthGuard>
            ),
        },
        {
            path: "/create-poc",
            element: (
                <AuthGuard allowedRoles={[UserRole.ADMIN, UserRole.EMPLOYEE]}>
                    <CreateFullPoc />
                </AuthGuard>
            ),
        },
        {
            path: "/pocs/:pocId/edit-poc",
            element: (
                <AuthGuard allowedRoles={[UserRole.ADMIN, UserRole.EMPLOYEE]}>
                    {" "}
                    <AccessWrapper>
                        <EditPoC />
                    </AccessWrapper>
                </AuthGuard>
            ),
        },
    ]);

    if (ssoInitCompleted) {
        return (
            <div>
                <Notification />
                <RouterProvider router={router} />
            </div>
        );
    } else {
        return <></>;
    }
}

export default App;
