import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import * as translationsEN from "./locales/en/translation.json";
import * as translationsDE from "./locales/de/translation.json";
import { LANGUAGE, ENLGISH, DEV_ENV } from "../utils/GlobalConstants";

const languages = {
    en: {
        translation: translationsEN,
    },
    de: {
        translation: translationsDE,
    },
};

i18n.use(initReactI18next).init({
    lng: `${window.localStorage.getItem(LANGUAGE)}`,
    fallbackLng: ENLGISH,
    debug: process.env.NODE_ENV === DEV_ENV ? true : false,
    interpolation: {
        escapeValue: false,
    },
    resources: languages,
});

export default i18n;
