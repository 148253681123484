import { useState } from "react";
import CreatePoC from "./CreatePoC";
import EditPoC from "./EditPoc";
import Layout from "layouts/Layout";

const CreateFullPoc: React.FC = () => {
    const [pocId, setPocId] = useState<number | null>(null);

    return (
        <div>
            {!pocId ? (
                <Layout>
                    <CreatePoC onPocCreated={setPocId} />
                </Layout>
            ) : (
                <EditPoC pocIdProp={pocId} />
            )}
        </div>
    );
};
export default CreateFullPoc;
