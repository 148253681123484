const defaultSnippetContent = `
export default function App() {
  return (
    <div className="App">
      <h1>Hello CodeSandbox</h1>
      <h2>Start editing to see some magic happen!</h2>
    </div>
  );
}
`.trim();

export const mySandpackConfig = {
    defaultPreset: "react",
    presets: [
        {
            label: "React",
            name: "react",
            meta: "live",
            sandpackTemplate: "react",
            sandpackTheme: "light",
            snippetFileName: "/App.js",
            snippetLanguage: "jsx",
            initialSnippetContent: defaultSnippetContent,
        },
        {
            label: "Virtuoso",
            name: "virtuoso",
            meta: "live virtuoso",
            sandpackTemplate: "react-ts",
            sandpackTheme: "light",
            snippetFileName: "/App.tsx",
            initialSnippetContent: defaultSnippetContent,
            dependencies: {
                "react-virtuoso": "latest",
                "@ngneat/falso": "latest",
            },
        },
    ],
};
