import {
    FileEarmarkText,
    FileEarmarkImage,
    FileEarmarkCode,
    FileEarmarkPdf,
    FileEarmarkZip,
    FiletypeJsx,
    FiletypeTsx,
    FiletypeJs,
    FiletypeJava,
    FiletypeExe,
    File,
    FiletypeDoc,
    FiletypeXls,
    FiletypePpt,
} from "react-bootstrap-icons";

export const fileIconMapper = (mimeType: string) => {
    switch (mimeType) {
        case "application/pdf":
            return <FileEarmarkPdf />;
        case "image/jpeg":
        case "image/png":
            return <FileEarmarkImage />;
        case "text/plain":
            return <FileEarmarkText />;
        case "text/javascript":
            return <FiletypeJs />;
        case "text/jsx":
            return <FiletypeJsx />;
        case "text/tsx":
            return <FiletypeTsx />;
        case "application/typescript":
            return <FileEarmarkCode />;
        case "application/java":
            return <FiletypeJava />;
        case "application/x-msdownload":
            return <FiletypeExe />;
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            return <FiletypeDoc />;
        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
            return <FiletypeXls />;
        case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
            return <FiletypePpt />;

        case "application/zip":
        case "application/x-zip-compressed":
        case "application/x-rar-compressed":
            return <FileEarmarkZip />;

        // Add more mappings as needed
        default:
            return <File />;
    }
};
