import React, { useState } from "react";
import {
    Modal,
    Button,
    FormGroup,
    Label,
    Input,
    Card,
    CardBody,
    CardTitle,
    CloseButton,
} from "reactstrap";
import PocService from "../services/PocService";
import toast from "react-hot-toast";
import { handleSubmitFormErrorResponse } from "../utils/FormUtil";
import { Formik, Form, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";

const UploadArticleFile: React.FC<{
    pocId: number;
    onUploadSuccess: () => void;
}> = ({ pocId, onUploadSuccess }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [submitError, setSubmitError] = useState<string>("");
    const { t } = useTranslation();

    const toggleModal = () => {
        if (isOpen) {
            setSubmitError("");
        }
        setIsOpen(!isOpen);
    };

    const initialValues = { file: null };

    const validate = (values: any) => {
        const errors: any = {};
        if (!values.file) {
            errors.file = t("uploadArticleFiles.fileMustBeSelected");
        }
        return errors;
    };

    const handleUpload = async (values: any, { resetForm }: any) => {
        if (values.file && pocId) {
            try {
                await PocService.uploadFile(pocId, values.file);
                onUploadSuccess();
                toast.success(t("uploadArticleFiles.fileUploadedSuccessfully"));
                toggleModal();
                resetForm();
            } catch (error) {
                console.error("Error uploading file:", error);
                handleSubmitFormErrorResponse(
                    error,
                    null,
                    setSubmitError,
                    () => {},
                    t,
                );
            }
        }
    };
    const onFileChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setFieldValue: any,
    ) => {
        const file = event.currentTarget.files?.[0];
        setFieldValue("file", file);
        if (file) {
            setSubmitError("");
        }
    };
    return (
        <>
            <Button
                className="btn btn-primary mt-3"
                type="submit"
                color="primary"
                onClick={toggleModal}>
                {t("uploadArticleFiles.uploadFile")}
            </Button>
            <Modal isOpen={isOpen} toggle={toggleModal} className="poc-modal">
                <Card className="poc-card">
                    <div className="modal-header">
                        <CardTitle tag="h5" className="modal-title">
                            {t("uploadArticleFiles.uploadFile")}
                        </CardTitle>
                        <CloseButton onClick={toggleModal} />
                    </div>
                    <CardBody>
                        <Formik
                            initialValues={initialValues}
                            validate={validate}
                            onSubmit={handleUpload}>
                            {({ setFieldValue, resetForm }) => (
                                <Form>
                                    <FormGroup>
                                        <Label for="file">
                                            {t("uploadArticleFiles.selectFile")}
                                        </Label>
                                        <Input
                                            type="file"
                                            name="file"
                                            id="file"
                                            onChange={(event) =>
                                                onFileChange(
                                                    event,
                                                    setFieldValue,
                                                )
                                            }
                                        />
                                        <div className="file-size-info">
                                            {t(
                                                "uploadArticleFiles.allowedSize",
                                            )}
                                        </div>
                                        <ErrorMessage
                                            name="file"
                                            component="div"
                                            className="error-message"
                                        />
                                    </FormGroup>
                                    <Button type="submit" color="primary">
                                        {t("uploadArticleFiles.upload")}
                                    </Button>
                                </Form>
                            )}
                        </Formik>
                    </CardBody>
                </Card>
                {submitError && (
                    <div className="error-message">{submitError}</div>
                )}
            </Modal>
        </>
    );
};
export default UploadArticleFile;
