import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";

interface VoteSuggestionProps {
    suggestedPocId: number;
    hasVoted: boolean;
    onVoteChange: () => void;
}

const VoteSuggestion: React.FC<VoteSuggestionProps> = ({
    suggestedPocId,
    hasVoted,
    onVoteChange,
}) => {
    const { t } = useTranslation();

    return (
        <Button
            color={hasVoted ? "secondary" : "primary"}
            onClick={onVoteChange}>
            {hasVoted
                ? t("VoteSuggestion.removeVote")
                : t("VoteSuggestion.vote")}
        </Button>
    );
};

export default VoteSuggestion;
