import AuthService from "../auth/AuthService";
import pocApiAxios from "./PocApiAxiosBase";
import { SuggestionFormValues } from "../utils/FormUtil";

const SuggestionService = {
    async suggestPoC(formData: SuggestionFormValues): Promise<any> {
        const authToken = await AuthService.getToken();
        return pocApiAxios.post("/suggested-pocs", formData, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
    async getSuggestionById(id: number): Promise<any> {
        const authToken = await AuthService.getToken();
        return pocApiAxios.get<any>(`/suggested-pocs/${id}`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
    async deleteSuggestion(id: number): Promise<any> {
        const authToken = await AuthService.getToken();
        return pocApiAxios.delete(`/suggested-pocs/${id}`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
    async updateSuggestion(
        id: number,
        pocData: { name: string; description: string },
    ): Promise<any> {
        const authToken = await AuthService.getToken();
        return pocApiAxios.put(`/suggested-pocs/${id}`, pocData, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
    async voteForSuggestion(suggestedPocId: number): Promise<any> {
        const authToken = await AuthService.getToken();
        return pocApiAxios.put(
            `/suggested-pocs/${suggestedPocId}/vote`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            },
        );
    },
};

export default SuggestionService;
