import pocApiAxios, { IPaginatedResponse } from "./PocApiAxiosBase";

const PublishedPocsService = {
    getFilteredPublishedPocs(
        page: number,
        pageSize: number,
        filterByPocName?: string,
        filterByAuthorId?: number,
        filterByTagId?: number,
        sort?: string,
        order?: string,
    ): Promise<IPaginatedResponse<any>> {
        return new Promise<IPaginatedResponse<any>>((resolve, reject) => {
            let publishedPocsUrl = `/published-pocs?page=${page}&size=${pageSize}`;
            if (filterByAuthorId !== undefined) {
                publishedPocsUrl += `&filterByAuthor=${filterByAuthorId}`;
            }
            if (filterByTagId !== undefined) {
                publishedPocsUrl += `&filterByTag=${filterByTagId}`;
            }
            if (filterByPocName !== undefined) {
                publishedPocsUrl += `&filterByPocName=${encodeURIComponent(
                    filterByPocName,
                )}`;
            }
            if (sort) {
                publishedPocsUrl += `&sort=${sort}`;
            }
            if (order) {
                publishedPocsUrl += `&order=${order}`;
            }

            pocApiAxios
                .get<Array<any>>(publishedPocsUrl)
                .then((res) => {
                    const pagiantedResponse: IPaginatedResponse = {
                        totalCount: res.headers["x-total-count"],
                        items: res.data,
                    };
                    resolve(pagiantedResponse);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getPublishedPoc(slug: string): Promise<any> {
        return pocApiAxios.get<any>(`/published-pocs/${slug}`);
    },
};

export default PublishedPocsService;
