import AuthService from "../auth/AuthService";
import pocApiAxios from "./PocApiAxiosBase";

const TagService = {
    getTag(tagId: number): Promise<any> {
        return pocApiAxios.get<any>(`/tags/${tagId}`);
    },
    getAllTags(): Promise<any> {
        return pocApiAxios.get<any>(`/tags`);
    },
    async getPocTags(pocId: number): Promise<any> {
        let authToken = await AuthService.getToken();
        return pocApiAxios.get<any>(`/pocs/${pocId}/associatedTags`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
    async createTag(name: string): Promise<any> {
        let authToken = await AuthService.getToken();

        return pocApiAxios.post<any>(
            `/tags`,
            {
                name: name,
            },
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            },
        );
    },
    async associateTag(pocId: number, tagId: number): Promise<any> {
        let authToken = await AuthService.getToken();
        return pocApiAxios.post<any>(
            `/pocs/${pocId}/associatedTags?tagAssociationId=${tagId}`,
            {},
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            },
        );
    },
    async removeTagAssociation(pocId: number, tagId: number): Promise<any> {
        let authToken = await AuthService.getToken();
        return pocApiAxios.delete<any>(
            `/pocs/${pocId}/associatedTags/associatedTags?tagAssociationId=${tagId}`,
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            },
        );
    },
};

export default TagService;
