import { useEffect, useState } from "react";
import Layout from "../layouts/Layout";
import { Col, Row } from "reactstrap";
import PocService from "../services/PocService";
import { useTranslation } from "react-i18next";
function ProtectedPageExample() {
    const [poc, setPoc] = useState<any>();
    const { t } = useTranslation();

    useEffect(() => {
        getPoc();
    }, []);

    const getPoc = () => {
        PocService.getPoc(1).then((res) => {
            setPoc(res.data);
        });
    };

    return (
        <Layout>
            <Row>
                <Col xs="12">
                    <div className="home">
                        <p>{t("protectedPageExample.protectedPageExample")}</p>
                        <p>POC #1: ${JSON.stringify(poc)}</p>
                    </div>
                </Col>
            </Row>
        </Layout>
    );
}

export default ProtectedPageExample;
