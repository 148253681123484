import { PencilSquare } from "react-bootstrap-icons";

import { Button } from "reactstrap";
import { Link } from "react-router-dom";

export default function EditSuggestion({
    suggestionId,
}: {
    suggestionId: number;
}) {
    return (
        <>
            <Link to={`/suggested-pocs/${suggestionId}/edit-suggestion`}>
                <Button>
                    <PencilSquare size={30} color="#6fa8fe" />
                </Button>
            </Link>
        </>
    );
}
