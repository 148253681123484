import { FormikHelpers } from "formik";
import { TFunction } from "i18next";

interface BackendError {
    fieldName: string;
    errorMessage: string;
    errorCode?: number;
}

interface BackendErrorsResponse {
    errors: BackendError[];
}

export interface FormValues {
    name: string;
    slug: string;
    description: string;
}
export interface SuggestionFormValues {
    name: string;
    description: string;
}

export const handleSubmitFormErrorResponse = (
    error: any,
    formikHelpers:
        | FormikHelpers<FormValues>
        | FormikHelpers<SuggestionFormValues>
        | null,
    setSubmitError: React.Dispatch<React.SetStateAction<string>>,
    resetSubmitting: () => void,
    t: TFunction,
) => {
    if (error.response) {
        const { status, data } = error.response;

        switch (status) {
            case 400:
                if (formikHelpers) {
                    (data as BackendErrorsResponse).errors.forEach((error) => {
                        formikHelpers.setFieldError(
                            error.fieldName,
                            error.errorMessage,
                        );
                    });
                }
                break;
            case 401:
                setSubmitError(t("error.error401"));
                break;
            case 403:
                setSubmitError(t("error.error403"));
                break;
            case 404:
                setSubmitError(t("error.error404"));
                break;
            case 409:
                setSubmitError(t("error.error409"));
                break;
            case 500:
                setSubmitError(t("error.error500"));
                break;
            default:
                setSubmitError(t("error.defaultError"));
                break;
        }
    } else {
        setSubmitError(t("error.defaultError"));
    }
    resetSubmitting();
};
