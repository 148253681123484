export const LANGUAGE: string = "language";

export const ENLGISH: string = "en";

export const GERMAN: string = "de";

export const DEV_ENV: string = "development";

export const AUTHORS_PAGE: string = "authors";

export const TAGS_PAGE: string = "tags";
