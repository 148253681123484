import { useEffect, useState } from "react";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "../styles/LanguagePicker.scss";
import { LANGUAGE, ENLGISH, GERMAN } from "../utils/GlobalConstants";

const LanguagePicker = () => {
    const localStorageLng: string = window.localStorage.getItem(LANGUAGE)
        ? window.localStorage.getItem(LANGUAGE) === ENLGISH
            ? ENLGISH
            : GERMAN
        : ENLGISH;
    const [chosenLanguage, setLanguage] = useState<string>(localStorageLng);
    const { i18n } = useTranslation();
    useEffect(() => {
        window.localStorage.setItem(LANGUAGE, chosenLanguage);
    }, [chosenLanguage]);

    const handleLanguageChange = (lang: string) => {
        i18n.changeLanguage(lang);
        setLanguage(lang);
    };

    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
    const flag = chosenLanguage === ENLGISH ? "🇬🇧" : "🇩🇪";

    return (
        <div className="d-flex p-2">
            <Dropdown
                className="language-picker--dropdown"
                isOpen={dropdownOpen}
                toggle={toggleDropdown}>
                <DropdownToggle caret>{flag}</DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => handleLanguageChange("en")}>
                        🇬🇧 English
                    </DropdownItem>
                    <DropdownItem onClick={() => handleLanguageChange("de")}>
                        🇩🇪 German
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default LanguagePicker;
