import { useRef, useState, useEffect } from "react";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { Button, CloseButton, Modal } from "reactstrap";
import placeholder from "../resources/placeholder.jpg";
import "styles/ImageCrop.scss";
import CoverImageService from "services/CoverImageService";
import DeleteCoverImage from "./DeleteCoverImage";

interface ImageCropProps {
    widthAspect: number;
    heightAspect: number;
    pocId: number;
    coverImageUrl?: string | null;
    onSuccess: () => void;
    refreshCoverImage: () => void;
}

const ImageCrop: React.FC<ImageCropProps> = ({
    widthAspect,
    heightAspect,
    pocId,
    coverImageUrl,
    onSuccess,
    refreshCoverImage,
}) => {
    const [cropped, setCropped] = useState({ x: 0, y: 0 });
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [file, setFile] = useState("");
    const [fileName, setFileName] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [croppedImage, setCroppedImage] = useState<string | null>(null);
    const hiddenFileInput: any = useRef(null);

    const handleFileUploadClick = () => {
        hiddenFileInput.current.click();
    };

    const { t } = useTranslation();

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    useEffect(() => {
        if (coverImageUrl) {
            setCroppedImage(coverImageUrl);
        }
    }, [coverImageUrl]);

    const createImage: any = (url: string) =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", (error) => reject(error));
            image.setAttribute("crossOrigin", "anonymous");
            image.src = url;
        });

    function getRadianAngle(degreeValue: number) {
        return (degreeValue * Math.PI) / 180;
    }

    function rotateSize(width: number, height: number, rotation: number) {
        const rotRad = getRadianAngle(rotation);

        return {
            width:
                Math.abs(Math.cos(rotRad) * width) +
                Math.abs(Math.sin(rotRad) * height),
            height:
                Math.abs(Math.sin(rotRad) * width) +
                Math.abs(Math.cos(rotRad) * height),
        };
    }

    const getCroppedImg: any = async (
        imageSrc: string,
        pixelCrop: any,
        cropWidth: number,
        cropHeight: number,
        rotation = 0,
        flip = { horizontal: false, vertical: false },
        callBack: BlobCallback,
    ) => {
        const image = await createImage(imageSrc);
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (!ctx) {
            return null;
        }

        const rotRad = getRadianAngle(rotation);

        // calculate bounding box of the rotated image
        const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
            image.width,
            image.height,
            rotation,
        );

        // set canvas size to match the bounding box
        canvas.width = bBoxWidth;
        canvas.height = bBoxHeight;

        // translate canvas context to a central location to allow rotating and flipping around the center
        ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
        ctx.rotate(rotRad);
        ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
        ctx.translate(-image.width / 2, -image.height / 2);

        // draw rotated image
        ctx.drawImage(image, 0, 0);

        const croppedCanvas = document.createElement("canvas");

        const croppedCtx = croppedCanvas.getContext("2d");

        if (!croppedCtx) {
            return null;
        }

        // Set the size of the cropped canvas
        croppedCanvas.width = cropWidth;
        croppedCanvas.height = cropHeight;

        // Draw the cropped image onto the new canvas
        croppedCtx.drawImage(
            canvas,
            pixelCrop.x,
            pixelCrop.y,
            pixelCrop.width,
            pixelCrop.height,
            0,
            0,
            cropWidth,
            cropHeight,
        );

        croppedCanvas.toBlob(callBack);
        return croppedCanvas.toDataURL("image/jpeg");
    };

    function handleChange(e: any) {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                setFile(reader.result as string),
            );
            reader.readAsDataURL(e.target.files[0]);
            setFileName(e.target.files[0].name);
            toggleModal();
        }
    }
    const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
        setCropped(croppedAreaPixels);
    };

    const saveImage = async () => {
        const cropWidth = widthAspect;
        const cropHeight = heightAspect;
        const croppedImage = await getCroppedImg(
            file,
            cropped,
            cropWidth,
            cropHeight,
            0,
            { horizontal: false, vertical: false },
            async (blob: any) => {
                await CoverImageService.addCoverImage(pocId, blob, fileName);
            },
        );

        onSuccess();
        setCroppedImage(croppedImage);
        toggleModal();
    };

    return (
        <>
            <div className="image-crop-container">
                <div className="fileDiv">
                    <img
                        onClick={handleFileUploadClick}
                        className="croppedImage"
                        width={widthAspect}
                        height={heightAspect}
                        src={croppedImage || placeholder}
                        alt="imageHere"
                        style={{
                            width: widthAspect,
                            height: heightAspect,
                            objectFit: "cover",
                        }}
                    />

                    <div
                        className={`image-info-overlay ${
                            coverImageUrl ? "image-present-overlay" : ""
                        }`}>
                        <p>
                            {t("coverImage.clickToUploadCoverImageBefore")}
                            <span className="upload-link">
                                {t("coverImage.clickToUploadCoverImageLink")}
                            </span>
                            {t("coverImage.clickToUploadCoverImageAfter")}
                        </p>
                        <p>{t("coverImage.allowedFormats")}: JPG, JPEG, PNG.</p>
                        <p>{t("coverImage.maximumSize")}: 50MB</p>
                    </div>
                    {croppedImage && (
                        <DeleteCoverImage
                            pocId={pocId}
                            refreshCoverImage={refreshCoverImage}
                            className="delete-cover-image-btn"
                        />
                    )}
                    <div
                        className={`image-info-overlay ${
                            coverImageUrl ? "image-present-overlay" : ""
                        }`}></div>
                    <div className="filePickerContainer">
                        <input
                            ref={hiddenFileInput}
                            className="filePicker"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={handleChange}
                            style={{ display: "none" }}
                        />
                    </div>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                toggle={toggleModal}
                className="poc-modal modal-lg">
                <div className="modal-header">
                    <CloseButton onClick={toggleModal} />
                </div>
                <div className="crop-container">
                    <Cropper
                        image={file}
                        crop={crop}
                        zoom={zoom}
                        objectFit="cover"
                        aspect={widthAspect / heightAspect}
                        zoomWithScroll={true}
                        zoomSpeed={0.25}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                </div>
                <div className="saveButtonDiv">
                    <Button
                        color="primary"
                        className="saveButton"
                        outline
                        onClick={saveImage}>
                        {t("coverImage.save")}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default ImageCrop;
