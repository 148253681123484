import { Row } from "reactstrap";
import Layout from "../layouts/Layout";
import "../styles/NoPermissions.scss";
import { useTranslation } from "react-i18next";

function NoPermissions() {
    const { t } = useTranslation();
    return (
        <Layout>
            <Row className="min-vh-75 align-items-center">
                <p className="text-center">
                    {t("protectedPage.noPermission")}
                    <br />
                    {t("protectedPage.contactAdmin")}
                </p>
            </Row>
        </Layout>
    );
}

export default NoPermissions;
