import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, CardText, CardTitle } from "reactstrap";
import "../styles/PocCard.scss";
import { useTranslation } from "react-i18next";
import { PublishedFilterEnum } from "./filters/PublishedFilter";

interface PocProps {
    id: number;
    published?: PublishedFilterEnum;
    name: string;
    slug: string;
    description: string;
    authors: string[];
    tags: string[];
    deleteButton: React.ReactNode;
    editButton: React.ReactNode;
    isPublished: boolean;
    coverImageUrl?: string;
}

const PocCard: React.FC<PocProps> = ({
    id,
    published,
    name,
    slug,
    description,
    authors,
    tags,
    deleteButton,
    editButton,
    isPublished,
    coverImageUrl,
}: PocProps) => {
    const { t } = useTranslation();

    const pocLink = isPublished ? `/poc/${slug}` : `/pocs/${id}`;
    const imageSrc = coverImageUrl;
    return (
        <div>
            <Card className="poc-card" data-test="PocCard">
                <div className="poc-card_image-wrapper">
                    <img
                        className="poc-card_image-wrapper_img"
                        src={imageSrc}
                        alt="PoC Cover"
                    />
                    {published && published === PublishedFilterEnum.DRAFT && (
                        <div className="poc-card_image-wrapper_banner">
                            <p className="poc-card_image-wrapper_banner_text">
                                {t("publishedFilter.draft")}
                            </p>
                        </div>
                    )}
                </div>
                <CardBody className="poc-card_body">
                    <CardTitle title={name}>
                        <Link to={pocLink}>{name}</Link>
                    </CardTitle>
                    <CardText
                        className="poc-card_body_text"
                        title={description}>
                        {description.substring(0, 150) + "\u00A0"}...
                    </CardText>
                </CardBody>
                <CardFooter>
                    <div className="card-footer-content">
                        <p className="card-authors">
                            <span
                                className="label-bold"
                                title={authors.join(", ")}>
                                <u>{t("pocCard.authors")}:</u>
                            </span>{" "}
                            {authors.join(", ")}
                        </p>
                        <p className="card-tags">
                            <span
                                className="label-bold"
                                title={tags.join(", ")}>
                                <u>{t("pocCard.tags")}:</u>
                            </span>{" "}
                            {tags.join(", ")}
                        </p>
                        <div className="card-footer-buttons">
                            <div className="">{editButton}</div>
                            <div className="ms-2">{deleteButton}</div>
                        </div>
                    </div>
                </CardFooter>
            </Card>
        </div>
    );
};

export default PocCard;
