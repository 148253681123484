import { Spinner } from "reactstrap";

const CenteredLoader = () => {
    return (
        <div
            className="d-flex justify-content-center align-items-center loader-container"
            data-test="Loader"
        >
            <Spinner color="primary" />
        </div>
    );
};

export default CenteredLoader;
