import AuthService from "auth/AuthService";
import pocApiAxios, { IPaginatedResponse } from "./PocApiAxiosBase";
const PocsService = {
    async getPocs(
        page: number,
        pageSize: number,
        published: string,
        filterByPocName?: string,
        filterByAuthorId?: number,
        filterByTagId?: number,
        sort?: string,
        order?: string,
    ): Promise<IPaginatedResponse<any>> {
        const authToken = await AuthService.getToken();
        let pocsUrl = `/pocs?page=${page}&size=${pageSize}&published=${published}`;

        if (filterByPocName) {
            pocsUrl += `&filterByPocName=${encodeURIComponent(
                filterByPocName,
            )}`;
        }
        if (filterByAuthorId !== undefined) {
            pocsUrl += `&filterByAuthor=${filterByAuthorId}`;
        }
        if (filterByTagId !== undefined) {
            pocsUrl += `&filterByTag=${filterByTagId}`;
        }
        if (sort) {
            pocsUrl += `&sort=${sort}`;
        }
        if (order) {
            pocsUrl += `&order=${order}`;
        }

        return new Promise<IPaginatedResponse<any>>((resolve, reject) => {
            pocApiAxios
                .get<Array<any>>(pocsUrl, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                })
                .then((res) => {
                    const paginatedResponse: IPaginatedResponse = {
                        totalCount: res.headers["x-total-count"],
                        items: res.data,
                    };
                    resolve(paginatedResponse);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default PocsService;
