import AuthService from "../auth/AuthService";
import { useTranslation } from "react-i18next";
import "styles/WelcomeMessage.scss";
const WelcomeMessage = () => {
    const { t } = useTranslation();
    const names = AuthService.getUserNames();
    return names ? (
        <div className="welcome-message">
            {t("navbar.message")}
            <b>{names}</b>.
        </div>
    ) : null;
};

export default WelcomeMessage;
