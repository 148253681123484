import AuthService from "../auth/AuthService";
import pocApiAxios from "./PocApiAxiosBase";

const CoverImageService = {
    async addCoverImage(
        pocId: number,
        blob: any,
        fileName: string,
    ): Promise<any> {
        let authToken = await AuthService.getToken();
        let formData = new FormData();
        formData.append("file", blob, `${fileName}`);
        const options = {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
            },
        };

        return pocApiAxios.put<any>(
            `/pocs/${pocId}/cover-image`,
            formData,
            options,
        );
    },
    async deleteCoverImage(pocId: number): Promise<any> {
        let authToken = await AuthService.getToken();
        const options = {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        };

        return pocApiAxios.delete(`/pocs/${pocId}/cover-image`, options);
    },
};

export default CoverImageService;
