import { Toaster } from "react-hot-toast";

export function Notification() {
    return (
        <div>
            <Toaster
                reverseOrder={false}
                position="top-center"
                toastOptions={{
                    success: {
                        style: {
                            borderRadius: "8px",
                            background: "#32363b",
                            color: "#006ec8",
                        },
                    },
                    error: {
                        style: {
                            borderRadius: "8px",
                            background: "#32363b",
                            color: "red",
                        },
                    },
                    loading: {
                        style: {
                            borderRadius: "8px",
                            background: "#32363b",
                            color: "white",
                        },
                    },
                }}
            />
        </div>
    );
}
