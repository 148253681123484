import { useEffect, useRef, useState } from "react";
import AuthService from "./AuthService";
import CenteredLoader from "../components/CenteredLoader";
import NoPermissions from "./NoPermissions";
import UserRole from "./enums/UserRole";

interface AuthGuardProps {
    allowedRoles: UserRole[];
    children: React.ReactNode;
}

const AuthGuard: React.FC<AuthGuardProps> = ({
    allowedRoles,
    children,
}: AuthGuardProps) => {
    const isRunOnceEffectExecuted = useRef(false);
    const [authenticationCompleted, setAuthenticationCompleted] =
        useState<Boolean>(false);

    useEffect(() => {
        if (!isRunOnceEffectExecuted.current) {
            AuthService.authenticate(() => {
                setAuthenticationCompleted(true);
            });
            isRunOnceEffectExecuted.current = true;
        }
    }, []);

    return authenticationCompleted ? (
        !AuthService.hasAnyOfRoles(allowedRoles) ? (
            <NoPermissions />
        ) : (
            <>{children}</>
        )
    ) : (
        // TODO: provide more suitable loader here
        <CenteredLoader />
    );
};

export default AuthGuard;
