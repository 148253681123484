import React, { useState } from "react";
import {
    Button,
    Input,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import SuggestionGrid from "./SuggestionGrid";
import "styles/SuggestionGrid.scss";

const SuggestionFilterGrid: React.FC = () => {
    const [searchInput, setSearchInput] = useState("");
    const [filterByPocName, setFilterByPocName] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [sortField, setSortField] = useState("updatedAt");
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortDropdownOpen, setSortDropdownOpen] = useState(false);
    const { t } = useTranslation();

    const handleSearch = () => {
        setFilterByPocName(searchInput);
        setCurrentPage(0);
    };

    const toggleSortDropdown = () => setSortDropdownOpen(!sortDropdownOpen);

    const handleSortFieldChange = (field: string) => {
        setSortField(field);
        setCurrentPage(0);
    };

    const handleSortOrderChange = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };
    const handlePressEnter = (event: any) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };
    return (
        <div>
            <Row className="sorting-controls">
                <Col lg={3}>
                    <Dropdown
                        isOpen={sortDropdownOpen}
                        toggle={toggleSortDropdown}>
                        <DropdownToggle caret color="primary">
                            {t("grid.sortedBy")}
                            {t(`grid.sortBy.${sortField}` as any)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => handleSortFieldChange("name")}>
                                {t("suggestionGrid.sortByName")}
                            </DropdownItem>
                            <DropdownItem
                                onClick={() =>
                                    handleSortFieldChange("createdAt")
                                }>
                                {t("suggestionGrid.sortByCreationDate")}
                            </DropdownItem>
                            <DropdownItem
                                onClick={() =>
                                    handleSortFieldChange("updatedAt")
                                }>
                                {t("suggestionGrid.sortByLastUpdated")}
                            </DropdownItem>
                            <DropdownItem
                                onClick={() =>
                                    handleSortFieldChange("totalVotes")
                                }>
                                {t("suggestionGrid.sortByTotalVotes")}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                <Col lg={1}>
                    <Button onClick={handleSortOrderChange} color="primary">
                        {sortOrder === "asc" ? "↑" : "↓"}
                    </Button>
                </Col>
            </Row>
            <Row className="search-row">
                <Col lg={11} className="search-input-group">
                    <Input
                        type="text"
                        className="form-control search-input"
                        placeholder={t("suggestionGrid.SearchSuggestionByName")}
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        onKeyDown={handlePressEnter}
                    />
                </Col>
                <Col lg={1}>
                    <Button
                        color="primary"
                        className="search-button"
                        onClick={handleSearch}>
                        {t("suggestionGrid.search")}
                    </Button>
                </Col>
            </Row>
            <SuggestionGrid
                filterByPocName={filterByPocName}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                sortField={sortField}
                sortOrder={sortOrder}
            />
        </div>
    );
};

export default SuggestionFilterGrid;
