import React, { Dispatch, SetStateAction } from "react";
import { Button, Card, CloseButton, CardFooter, Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import PocService from "../services/PocService";
import "../styles/ModalDialogFooter.scss";

interface ManagePublishedDialogModalProps {
    pocId: number;
    isPublished: boolean;
    setIsPublished: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
    toggleModal: () => void;
}

const ManagePublishedDialogModal: React.FC<ManagePublishedDialogModalProps> = (
    props: ManagePublishedDialogModalProps,
) => {
    const { t } = useTranslation();

    const handleManagePublishedPoC = async () => {
        if (props.pocId) {
            try {
                const response = await PocService.managePocPublishedStatus(
                    props.pocId,
                );
                props.setIsPublished(response.data.published);
                toast.success(
                    response.data.published
                        ? t("editPocArticle.managePublished.published")
                        : t("editPocArticle.managePublished.unpublished"),
                );
                props.toggleModal();
            } catch (error) {
                toast.error(
                    t("editPocArticle.managePublished.changeStatusError"),
                );
            }
        }
    };

    return (
        <Modal
            isOpen={props.isModalOpen}
            toggle={props.toggleModal}
            className="poc-modal">
            <Card className="poc-card">
                <div className="modal-header">
                    <h5 className="modal-title">
                        {props.isPublished
                            ? t(
                                  "editPocArticle.managePublished.confirmUnpublish",
                              )
                            : t(
                                  "editPocArticle.managePublished.confirmPublish",
                              )}
                    </h5>
                    <CloseButton
                        onClick={props.toggleModal}
                        className="ml-auto"
                    />
                </div>
                <CardFooter className="modal-poc-footer">
                    <Button color="danger" onClick={handleManagePublishedPoC}>
                        {t("yes")}
                    </Button>
                    <Button color="secondary" onClick={props.toggleModal}>
                        {t("no")}
                    </Button>
                </CardFooter>
            </Card>
        </Modal>
    );
};

export default ManagePublishedDialogModal;
