import { Card, CardBody, CardTitle, CardText, CardFooter } from "reactstrap";
import { Link } from "react-router-dom";
import "../styles/PocCard.scss";
import { useTranslation } from "react-i18next";
interface Suggester {
    id: number;
    externalUserId: string;
    firstName: string;
    lastName: string;
    shortBio?: string;
    pictureUrl?: string;
}

interface Suggestion {
    id: number;
    name: string;
    description: string;
    suggester: Suggester;
    totalVotes: number;
    deleteButton?: React.ReactNode;
    editButton?: React.ReactNode;
}

const SuggestionCard: React.FC<Suggestion> = ({
    id,
    name,
    description,
    suggester,
    totalVotes,
    deleteButton,
    editButton,
}) => {
    const { t } = useTranslation();
    const suggestionLink = `/suggested-pocs/${id}`;
    return (
        <div>
            <Card className="poc-card">
                <CardBody className="poc-card_body">
                    <CardTitle title={name} tag="h5">
                        <Link to={suggestionLink}>{name}</Link>
                    </CardTitle>
                    <CardText
                        className="poc-card_body_text"
                        title={description}>
                        {description.substring(0, 150) + "\u00A0"}...
                    </CardText>
                </CardBody>
                <CardFooter>
                    <div className="card-footer-content">
                        <p className="card-authors">
                            <span className="label-bold">
                                {t("suggestionCard.suggester")}:
                            </span>{" "}
                            {suggester.firstName} {suggester.lastName}
                        </p>
                        <p className="card-tags">
                            <span className="label-bold">
                                {t("suggestionCard.votes")}:
                            </span>{" "}
                            {totalVotes}
                        </p>
                        <div className="card-footer-buttons">
                            {deleteButton && (
                                <div className="ms-2">{deleteButton}</div>
                            )}{" "}
                            {editButton && (
                                <div className="ms-2">{editButton}</div>
                            )}{" "}
                        </div>
                    </div>
                </CardFooter>
            </Card>
        </div>
    );
};

export default SuggestionCard;
