import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Layout from "../layouts/Layout";
import AuthorAvatarImage from "../components/AuthorAvatarImage";
import AuthorService from "../services/AuthorService";
import CenteredLoader from "../components/CenteredLoader";
import FilterGrid from "../components/PocsFilterGrid";
import { Container, Row } from "reactstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { AUTHORS_PAGE } from "utils/GlobalConstants";

function Author() {
    const { authorId } = useParams<{ authorId: string }>();
    const authorIdNumeric: number = authorId ? parseInt(authorId) : 0;

    const [author, setAuthor] = useState<any | null>(null);
    const [isGetAuthorInProgress, setIsGetAuthorInProgress] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        loadAuthor();
    }, []);

    const loadAuthor = () => {
        if (authorIdNumeric !== 0) {
            AuthorService.getAuthor(authorIdNumeric)
                .then((res) => {
                    setAuthor(res.data);
                    setIsGetAuthorInProgress(false);
                })
                .catch((e) => {
                    if (axios.isCancel(e)) {
                        // noop - we shouldn't handle this type of error
                        return;
                    }
                    setIsGetAuthorInProgress(false);
                    if (e.response && e.response.status === 404) {
                        // Handle the 404 error with a user-friendly message
                        setError(t("error.pageNotFound"));
                    } else {
                        // Handle other errors
                        setError(t("error.fetchError"));
                        console.log(e);
                    }
                });
        }
    };

    return (
        <Layout>
            {isGetAuthorInProgress && <CenteredLoader />}
            {error != null && (
                // Display the error message for 404 or other errors
                <div>
                    <h1>{t("error.error")}</h1>
                    <p>{error}</p>
                </div>
            )}
            {author !== null && (
                <>
                    <Container>
                        <Row>
                            <div className="col-md-2 text-center">
                                <AuthorAvatarImage
                                    photourl={author.pictureUrl}
                                    pixelWidth={150}
                                />
                                <h5>
                                    {author.firstName}&nbsp;{author.lastName}
                                </h5>
                            </div>
                            <div className="col-md-10">
                                <h5>{t("author.aboutAuthor")}:</h5>
                                <p style={{ whiteSpace: "pre-wrap" }}>
                                    {author.shortBio}
                                </p>
                            </div>
                        </Row>
                    </Container>
                    <h2 className="mt-5">{t("author.authoredPocs")}:</h2>
                    <FilterGrid
                        filterByAuthorId={author.id}
                        accessedFrom={AUTHORS_PAGE}
                    />
                </>
            )}
        </Layout>
    );
}

export default Author;
