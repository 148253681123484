import AuthService from "auth/AuthService";
import pocApiAxios from "./PocApiAxiosBase";

const PocLinkService = {
    async savePocLinks(url: string, pocId: number, type: string): Promise<any> {
        const authToken = await AuthService.getToken();
        let pocsUrl = `/pocs/${pocId}/links`;

        if (type === "sourceCode") {
            pocsUrl = `${pocsUrl}/source-code?sourceCodeLink=${url}`;
        } else if (type === "caseStudy") {
            pocsUrl = `${pocsUrl}/case-study?caseStudyLink=${url}`;
        }
        const options = {
            headers: {
                Authorization: `Bearer ${authToken}`,
            }
        }
        return pocApiAxios.post<Array<any>>(pocsUrl, {}, options);
    },
};

export default PocLinkService;
