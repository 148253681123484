import React from "react";
import {
    Col,
    Container,
    Nav,
    NavItem,
    Navbar,
    NavbarBrand,
    Row,
} from "reactstrap";
import AuthService from "../auth/AuthService";
import { NavLink } from "react-router-dom";
import ProtectedNavigationLink from "../components/ProtectedNavigationLink";
import UserRole from "../auth/enums/UserRole";
import LanguagePicker from "../components/LanguagePicker";
import { useTranslation } from "react-i18next";
import "../styles/Layout.scss";
import WelcomeMessage from "components/WelcomeMessage";

interface LayoutProps {
    children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
    const { t } = useTranslation();

    return (
        <div>
            <header>
                <Container>
                    <Navbar expand="md">
                        <NavbarBrand href="/">
                            <img src="/Adesso_AG_logo.png" alt="Adesso Logo" />
                            {t("navbar.platformName")}
                        </NavbarBrand>

                        <Nav>
                            <NavItem>
                                <ProtectedNavigationLink
                                    linkName={t("navLink.suggestPoC")}
                                    loginInTooltip={t(
                                        "toolTip.loginToSuggestPoC",
                                    )}
                                    authorizationTooltip={t(
                                        "toolTip.loginToSuggestPoC",
                                    )}
                                    allowedRoles={[
                                        UserRole.ADMIN,
                                        UserRole.EMPLOYEE,
                                    ]}
                                    httpLink="/suggest-poc"
                                />
                            </NavItem>
                            <NavItem>
                                <ProtectedNavigationLink
                                    linkName={t("navLink.createPoC")}
                                    loginInTooltip={t(
                                        "toolTip.loginToCreatePoC",
                                    )}
                                    authorizationTooltip={t(
                                        "toolTip.notAuthorizedToCreateAPoC",
                                    )}
                                    allowedRoles={[
                                        UserRole.ADMIN,
                                        UserRole.EMPLOYEE,
                                    ]}
                                    httpLink="/create-poc"
                                />
                            </NavItem>
                            <NavItem>
                                <ProtectedNavigationLink
                                    linkName={t(
                                        "navLink.adminProtectedPageExample",
                                    )}
                                    loginInTooltip={t("toolTip.loginToView")}
                                    authorizationTooltip={t(
                                        "toolTip.notAuthorizedToView",
                                    )}
                                    allowedRoles={[UserRole.ADMIN]}
                                    httpLink="/protected-page"
                                />
                            </NavItem>
                            <NavItem>
                                {AuthService.isAuthenticated() ? (
                                    <NavLink
                                        to="/logout"
                                        className={"nav-link"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            AuthService.doLogout();
                                            return;
                                        }}>
                                        {t("navbar.logout")}
                                    </NavLink>
                                ) : (
                                    <NavLink
                                        to="/login"
                                        className={"nav-link"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            AuthService.authenticate();
                                        }}>
                                        {t("navbar.login")}
                                    </NavLink>
                                )}
                            </NavItem>
                            <LanguagePicker />
                        </Nav>
                    </Navbar>
                    <WelcomeMessage />
                    <hr className="bg-dark" />
                </Container>
            </header>
            <Container style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                {children}
            </Container>
            <footer style={{ textAlign: "center" }}>
                <Container fluid>
                    <Row>
                        <Col xs="12">
                            <p>
                                &copy; {new Date().getFullYear()}{" "}
                                {t("footer.adessoBulgaria")}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </div>
    );
};

export default Layout;
