import { Link } from "react-router-dom";
import AuthorAvatarImage from "./AuthorAvatarImage";

interface AuthorAvatarProps {
    name: string;
    photourl?: string | null | undefined;
    authorId: string;
    pixelWidth: number
}

const AuthorAvatar: React.FC<AuthorAvatarProps> = ({
    name,
    photourl,
    authorId,
    pixelWidth
}: AuthorAvatarProps) => {
    return (
        <Link to={`/author/${authorId}`}>
            <div className="text-center">
                <div>
                    <AuthorAvatarImage photourl={photourl} pixelWidth={pixelWidth} />
                </div>
                <span>{name}</span>
            </div>
        </Link>
    );
};

export default AuthorAvatar;
