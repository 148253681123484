import React, { Dispatch, SetStateAction, useState } from "react";
import {
    ListGroup,
    ListGroupItem,
    Modal,
    Card,
    CloseButton,
    CardFooter,
    Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import "../styles/AddAndRemoveLists.scss";

interface AddAndRemoveListsProps {
    itemsFound: any[];
    setItemsFound: Dispatch<SetStateAction<any[]>>;
    itemsAdded: any[];
    setItemsAdded: Dispatch<SetStateAction<any[]>>;
    addItem: (id: any, label: string | null) => void;
    removeItem: (id: any, label: string | null) => void;
    getListItemId: (item: any) => string;
    getListItemLabel: (item: any) => string;
    itemsAddedLabel: string;
    loggedUserId?: string;
}

const AddAndRemoveLists: React.FC<AddAndRemoveListsProps> = (
    props: AddAndRemoveListsProps,
) => {
    const { t } = useTranslation();
    const [removeLoggedAuthor, setRemoveLoggedAuthor] =
        useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<any>();

    const toggleRemoveLoggedAuthorModal = (item: any) => {
        setRemoveLoggedAuthor(!removeLoggedAuthor);
        setItemToRemove(item);
    };

    const moveItem = (
        item: string,
        fromList: string[],
        toList: string[],
        setFromList: any,
        setToList: any,
    ) => {
        setFromList(fromList.filter((i) => i !== item));
        setToList([item, ...toList]);
    };

    const handleRemove = (item: any) => {
        console.log(props?.loggedUserId);
        console.log(item);
        if (props?.loggedUserId && props.loggedUserId === item.externalUserId) {
            toggleRemoveLoggedAuthorModal(item);
        } else {
            removeFromAssociated(item);
        }
    };

    const removeFromAssociated = (item: any) => {
        const idToRemove = item.externalUserId ? item.externalUserId : item.id;
        props.removeItem(idToRemove, item.name);

        moveItem(
            item,
            props.itemsAdded,
            props.itemsFound,
            props.setItemsAdded,
            props.setItemsFound,
        );
        setRemoveLoggedAuthor(false);
    };

    return (
        <div className="listContainer">
            <ListGroup className="addList" horizontal>
                {props.itemsFound.map((item, index) => (
                    <ListGroupItem
                        className="addListItem"
                        title={t("result.clickToAdd", {
                            label: props.getListItemLabel(item),
                        })}
                        onClick={(e) => {
                            const target = e.target as HTMLElement;
                            moveItem(
                                item,
                                props.itemsFound,
                                props.itemsAdded,
                                props.setItemsFound,
                                props.setItemsAdded,
                            );
                            props.addItem(target.id, target.textContent);
                        }}
                        id={props.getListItemId(item)}
                        key={index}>
                        {props.getListItemLabel(item)}
                    </ListGroupItem>
                ))}
            </ListGroup>
            <h2 className="itemsAddedLabel">{props.itemsAddedLabel}</h2>
            <ListGroup className="removeList" horizontal>
                {props.itemsAdded.map((item, index) => (
                    <ListGroupItem
                        className="removeListItem"
                        title={t("result.clickToRemove", {
                            label: props.getListItemLabel(item),
                        })}
                        onClick={() => {
                            handleRemove(item);
                        }}
                        id={props.getListItemId(item)}
                        key={index}>
                        {props.getListItemLabel(item)}
                    </ListGroupItem>
                ))}
            </ListGroup>
            <Modal
                isOpen={removeLoggedAuthor}
                toggle={toggleRemoveLoggedAuthorModal}
                className="poc-modal">
                <Card className="poc-card">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {t("author.removeLoggedUserConfirmation")}
                        </h5>
                        <CloseButton
                            onClick={toggleRemoveLoggedAuthorModal}
                            className="ml-auto"
                        />
                    </div>
                    <CardFooter className="modal-poc-footer">
                        <Button
                            color="danger"
                            onClick={() => removeFromAssociated(itemToRemove)}>
                            {t("yes")}
                        </Button>
                        <Button
                            color="secondary"
                            onClick={toggleRemoveLoggedAuthorModal}>
                            {t("no")}
                        </Button>
                    </CardFooter>
                </Card>
            </Modal>
        </div>
    );
};

export default AddAndRemoveLists;
