import { useEffect, useState, ReactNode } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AuthService from "auth/AuthService";
import PocService from "services/PocService";
import SuggestionService from "services/SuggestionService";
import CenteredLoader from "components/CenteredLoader";
import NoPermissions from "auth/NoPermissions";
import UserRole from "auth/enums/UserRole";

interface AccessWrapperProps {
    children: ReactNode;
}

const AccessWrapper: React.FC<AccessWrapperProps> = ({ children }) => {
    const { id, pocId } = useParams<{ id: string; pocId: string }>();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {
        const checkAccess = async () => {
            const isAdmin = AuthService.hasRole(UserRole.ADMIN);

            if (isAdmin) {
                setHasAccess(true);
                setIsLoading(false);
                return;
            }

            const userId = AuthService.getUserId();
            const contentId = id || pocId;

            if (!contentId) {
                setIsLoading(false);
                return;
            }

            try {
                const response = await (pocId
                    ? PocService.getPoc(parseInt(pocId))
                    : SuggestionService.getSuggestionById(parseInt(contentId)));

                let contentUserId;

                if (response.data.suggester) {
                    contentUserId = response.data.suggester.externalUserId;
                } else {
                    contentUserId = response.data.authors.find(
                        (author: any) => author.externalUserId === userId,
                    )?.externalUserId;
                }

                if (userId === contentUserId) {
                    setHasAccess(true);
                }
            } catch (error) {
                console.error("Failed to fetch content details:", error);
            } finally {
                setIsLoading(false);
            }
        };

        checkAccess();
    }, [id, pocId, navigate]);

    if (isLoading) {
        return <CenteredLoader />;
    }

    if (!hasAccess) {
        return <NoPermissions />;
    }

    return <>{children}</>;
};

export default AccessWrapper;
