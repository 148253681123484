import { Button, ButtonGroup, Col } from "reactstrap";
import { useTranslation } from "react-i18next";

export const enum PublishedFilterEnum {
    PUBLISHED = "published",
    DRAFT = "draft",
    ALL = "all",
}

interface PublishedFilterProps {
    published: PublishedFilterEnum;
    setPublished: (arg: PublishedFilterEnum) => void;
}

export default function PublishedFilter({
    published,
    setPublished,
}: PublishedFilterProps) {
    const { t } = useTranslation();

    return (
        <Col>
            <ButtonGroup>
                <Button
                    color="primary"
                    outline
                    onClick={() => setPublished(PublishedFilterEnum.ALL)}
                    active={published === PublishedFilterEnum.ALL}>
                    {t("publishedFilter.all")}
                </Button>
                <Button
                    color="primary"
                    outline
                    onClick={() => setPublished(PublishedFilterEnum.PUBLISHED)}
                    active={published === PublishedFilterEnum.PUBLISHED}>
                    {t("publishedFilter.published")}
                </Button>
                <Button
                    color="primary"
                    outline
                    onClick={() => setPublished(PublishedFilterEnum.DRAFT)}
                    active={published === PublishedFilterEnum.DRAFT}>
                    {t("publishedFilter.draft")}
                </Button>
            </ButtonGroup>
        </Col>
    );
}
