import AuthService from "../auth/AuthService";
import pocApiAxios from "./PocApiAxiosBase";
import { FormValues } from "../utils/FormUtil";

const PocService = {
    async getPoc(pocId: number): Promise<any> {
        let authToken = await AuthService.getToken();

        return pocApiAxios.get<any>(`/pocs/${pocId}`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },

    async updateArticleText(pocId: number, articleText: string): Promise<any> {
        const authToken = await AuthService.getToken();
        return pocApiAxios.put(
            `/pocs/${pocId}/article-text`,
            { articleText },
            {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            },
        );
    },

    async createPoC(formData: FormValues): Promise<any> {
        const authToken = await AuthService.getToken();
        return pocApiAxios.post("/pocs", formData, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
    async updatePoC(
        pocId: number,
        pocData: { name: string; slug: string; description: string },
    ): Promise<any> {
        const authToken = await AuthService.getToken();
        return pocApiAxios.put(`/pocs/${pocId}`, pocData, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
    async deletePoc(pocId: number): Promise<any> {
        const authToken = await AuthService.getToken();
        return pocApiAxios.delete(`/pocs/${pocId}`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
    async fetchFiles(pocId: number): Promise<any> {
        const authToken = await AuthService.getToken();
        return pocApiAxios.get(`/pocs/${pocId}/files`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
    async deleteFile(pocId: number, fileName: string): Promise<any> {
        const authToken = await AuthService.getToken();
        return pocApiAxios.delete(`/pocs/${pocId}/files/${fileName}`, {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        });
    },
    async uploadFile(pocId: number, file: File): Promise<any> {
        const formData = new FormData();
        formData.append("file", file);

        const authToken = await AuthService.getToken();
        return pocApiAxios.post(`/pocs/${pocId}/files`, formData, {
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "multipart/form-data",
            },
        });
    },

    async managePocPublishedStatus(pocId: number): Promise<any> {
        let authToken = await AuthService.getToken();
        const options = {
            headers: {
                Authorization: `Bearer ${authToken}`,
            },
        };

        return pocApiAxios.patch(`/pocs/${pocId}/publish`, {}, options);
    },
};

export default PocService;
