import { useState, useEffect } from "react";
import {
    Col,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input,
    Button,
} from "reactstrap";
import { useTranslation } from "react-i18next";

import "styles/PocsFilterGrid.scss";
import Grid from "components/PocsGrid";
import { PublishedFilterEnum } from "components/filters/PublishedFilter";
import PublishedFilter from "components/filters/PublishedFilter";
import AuthService from "../auth/AuthService";
import AuthorService from "services/AuthorService";
import TagService from "services/TagService";
import { AUTHORS_PAGE, TAGS_PAGE } from "utils/GlobalConstants";

interface FilterGridProps {
    filterByAuthorId?: number;
    filterByTagId?: number;
    accessedFrom?: string;
}

interface Author {
    id: number;
    firstName: string;
    lastName: string;
}

interface Tag {
    id: number;
    name: string;
}
export default function FilterGrid({
    filterByAuthorId,
    filterByTagId,
    accessedFrom,
}: FilterGridProps) {
    const [filters, setFilters] = useState({
        published: PublishedFilterEnum.PUBLISHED,
        page: 0,
    });
    const { t } = useTranslation();
    const [authorsList, setAuthorsList] = useState<Author[]>([]);
    const [filterByAuthor, setFilterByAuthor] = useState<number | undefined>(
        filterByAuthorId,
    );
    const [filteredAuthorsList, setFilteredAuthorsList] = useState<Author[]>(
        [],
    );
    const [tagsList, setTagsList] = useState<Tag[]>([]);
    const [filterByTag, setFilterByTag] = useState<number | undefined>(
        filterByTagId,
    );
    const [filteredTagsList, setFilteredTagsList] = useState<Tag[]>([]);

    const [searchByNameInput, setSearchByNameInput] = useState<string>("");
    const [searchByAuthorInput, setSearchByAuthorInput] = useState<string>("");
    const [searchByTagInput, setSearchByTagInput] = useState<string>("");
    const [authorDropdownOpen, setAuthorDropdownOpen] = useState(false);
    const [tagDropdownOpen, setTagDropdownOpen] = useState(false);
    const [filterByPocName, setfilterByPocName] = useState<string | undefined>(
        undefined,
    );
    const [sortField, setSortField] = useState("updatedAt");
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortDropdownOpen, setSortDropdownOpen] = useState(false);

    const toggleSortDropdown = () =>
        setSortDropdownOpen((prevState) => !prevState);
    const toggleAuthorDropdown = () =>
        setAuthorDropdownOpen((prevState) => !prevState);
    const toggleTagDropdown = () =>
        setTagDropdownOpen((prevState) => !prevState);

    useEffect(() => {
        const fetchAuthors = async () => {
            try {
                const { data } = await AuthorService.getAllAuthors();
                setAuthorsList(data);
            } catch (error) {
                console.error("Error fetching authors:", error);
            }
        };

        const fetchTags = async () => {
            try {
                const { data } = await TagService.getAllTags();
                setTagsList(data);
            } catch (error) {
                console.error("Error fetching tags:", error);
            }
        };

        fetchAuthors();
        fetchTags();
    }, []);
    useEffect(() => {
        if (searchByAuthorInput) {
            const lowercasedFilter = searchByAuthorInput.toLowerCase();
            const filteredAuthors = authorsList.filter(
                (author) =>
                    author.firstName
                        .toLowerCase()
                        .startsWith(lowercasedFilter) ||
                    author.lastName.toLowerCase().startsWith(lowercasedFilter),
            );
            setFilteredAuthorsList(filteredAuthors);
        } else {
            setFilteredAuthorsList([]);
        }
    }, [searchByAuthorInput, authorsList]);

    useEffect(() => {
        if (searchByTagInput) {
            const lowercasedFilter = searchByTagInput.toLowerCase();
            const filteredTags = tagsList.filter((tag) =>
                tag.name.toLowerCase().startsWith(lowercasedFilter),
            );
            setFilteredTagsList(filteredTags);
        } else {
            setFilteredTagsList([]);
        }
    }, [searchByTagInput, tagsList]);

    const setPublished = (published: PublishedFilterEnum): void => {
        setFilters({ published, page: 0 });
    };

    const setCurrentPage = (page: number): void => {
        setFilters({ ...filters, page: page });
    };

    const getAuthorsName = () => {
        let authorName: string = t("grid.selectAuthor");
        if (filterByAuthor) {
            const author = authorsList.find(
                (author) => author.id === filterByAuthor,
            );
            authorName = author?.firstName + " " + author?.lastName;
        }
        return authorName;
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchByNameInput(event.target.value);
    };
    const handleAuthorSearchChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setSearchByAuthorInput(event.target.value);
    };

    const handleTagSearchChange = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        setSearchByTagInput(event.target.value);
    };
    const handleSearch = () => {
        setfilterByPocName(searchByNameInput);
    };

    const handlePressEnter = (event: any) => {
        if (event.key === "Enter") {
            handleSearch();
        }
    };
    const handleSelectAllAuthors = () => {
        setFilterByAuthor(undefined);
        setSearchByAuthorInput("");
        setFilteredAuthorsList([]);
    };
    const handleSelectAllTags = () => {
        setFilterByTag(undefined);
        setSearchByTagInput("");
        setFilteredTagsList([]);
    };
    const handleSortFieldChange = (field: string) => {
        setSortField(field);
        setCurrentPage(0);
    };

    const handleSortOrderChange = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        setCurrentPage(0);
    };
    return (
        <div>
            <Row className="filters-container">
                <Col lg={6} className="published-filter">
                    {AuthService.isAuthenticated() ? (
                        <PublishedFilter
                            published={filters.published}
                            setPublished={setPublished}
                        />
                    ) : null}
                </Col>
                <Col lg={3}>
                    <Dropdown
                        isOpen={sortDropdownOpen}
                        toggle={toggleSortDropdown}>
                        <DropdownToggle caret color="primary">
                            {t("grid.sortedBy")}
                            {t(`grid.sortBy.${sortField}` as any)}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem
                                onClick={() => handleSortFieldChange("name")}>
                                {t("grid.sortBy.name")}
                            </DropdownItem>
                            <DropdownItem
                                onClick={() =>
                                    handleSortFieldChange("createdAt")
                                }>
                                {t("grid.sortBy.createdAt")}
                            </DropdownItem>
                            <DropdownItem
                                onClick={() =>
                                    handleSortFieldChange("updatedAt")
                                }>
                                {t("grid.sortBy.updatedAt")}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                <Col lg={1}>
                    <Button onClick={handleSortOrderChange} color="primary">
                        {sortOrder === "asc" ? "↑" : "↓"}
                    </Button>
                </Col>
            </Row>
            <Row className="filters-container">
                {accessedFrom !== AUTHORS_PAGE && (
                    <Col lg={3}>
                        <Dropdown
                            className="filters-author-dropdown"
                            isOpen={authorDropdownOpen}
                            toggle={toggleAuthorDropdown}>
                            <DropdownToggle caret color="primary">
                                {getAuthorsName()}
                            </DropdownToggle>
                            <DropdownMenu>
                                <Input
                                    type="text"
                                    placeholder={t("grid.searchAuthor")}
                                    onChange={handleAuthorSearchChange}
                                    value={searchByAuthorInput}
                                />
                                <DropdownItem onClick={handleSelectAllAuthors}>
                                    {t("grid.selectAll")}
                                </DropdownItem>
                                {searchByAuthorInput &&
                                    filteredAuthorsList.map((author) => (
                                        <DropdownItem
                                            key={author.id}
                                            onClick={() =>
                                                setFilterByAuthor(author.id)
                                            }>
                                            {author.firstName} {author.lastName}
                                        </DropdownItem>
                                    ))}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                )}
                {accessedFrom !== TAGS_PAGE && (
                    <Col lg={3}>
                        <Dropdown
                            className="filters-tag-dropdown"
                            isOpen={tagDropdownOpen}
                            toggle={toggleTagDropdown}>
                            <DropdownToggle caret color="primary">
                                {filterByTag
                                    ? tagsList.find(
                                          (tag) => tag.id === filterByTag,
                                      )?.name
                                    : t("grid.selectTag")}
                            </DropdownToggle>
                            <DropdownMenu>
                                <Input
                                    type="text"
                                    placeholder={t("grid.searchTag")}
                                    onChange={handleTagSearchChange}
                                    value={searchByTagInput}
                                />
                                <DropdownItem onClick={handleSelectAllTags}>
                                    {t("grid.selectAll")}
                                </DropdownItem>
                                {searchByTagInput &&
                                    filteredTagsList.map((tag) => (
                                        <DropdownItem
                                            key={tag.id}
                                            onClick={() =>
                                                setFilterByTag(tag.id)
                                            }>
                                            {tag.name}
                                        </DropdownItem>
                                    ))}
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                )}
                <Col lg={5}>
                    <Input
                        type="text"
                        value={searchByNameInput}
                        onChange={handleSearchChange}
                        placeholder={t("grid.SearchPoCByName")}
                        className="name-filter-input"
                        onKeyDown={handlePressEnter}
                    />
                </Col>
                <Col lg={1}>
                    <Button color="primary" onClick={handleSearch}>
                        {t("grid.search")}
                    </Button>
                </Col>
            </Row>
            <Grid
                published={filters.published}
                currentPage={filters.page}
                setCurrentPage={setCurrentPage}
                filterByAuthorId={filterByAuthor}
                filterByTagId={filterByTag}
                filterByPocName={filterByPocName}
                sortField={sortField}
                sortOrder={sortOrder}
            />
        </div>
    );
}
