import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Card,
    CardBody,
    CardTitle,
    CloseButton,
    Input,
    InputGroup,
    Modal,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import AddAndRemoveLists from "./AddAndRemoveLists";
import toast from "react-hot-toast";
import AuthorService from "../services/AuthorService";
import AuthService from "auth/AuthService";

interface AddAndRemoveAuthorsModalProps {
    pocId: number;
    toggleModal: () => void;
    isModalOpen: boolean;
    setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const AddAndRemoveAuthorsModal: React.FC<AddAndRemoveAuthorsModalProps> = (
    props: AddAndRemoveAuthorsModalProps,
) => {
    const [authorsFound, setAuthorsFound] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [allAuthors, setAllAuthors] = useState(Array<any>);
    const [authorsAdded, setAuthorsAdded] = useState<any[]>([]);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const loggedUserId = AuthService.getUserId();

    useEffect(() => {
        AuthorService.getPocAuthors(props.pocId).then((res) => {
            setAuthorsAdded(
                res.data.map((item: any) => ({
                    externalUserId: item.externalUserId,
                    id: item.id,
                    name: `${item.firstName} ${item.lastName}`,
                    firstName: item.firstName,
                    lastName: item.lastName,
                })),
            );
        });

        AuthorService.getAllAuthors().then((res) => {
            setAllAuthors(
                res.data.map((item: any) => ({
                    externalUserId: item.externalUserId,
                    id: item.id,
                    name: `${item.firstName} ${item.lastName}`,
                    firstName: item.firstName,
                    lastName: item.lastName,
                })),
            );
        });
    }, [props.pocId]);

    const handleSearch = (input: string) => {
        setSearchTerm(input);
        if (input !== "" && input.length >= 1) {
            setAuthorsFound(
                allAuthors.filter(
                    (item: any) =>
                        (item.firstName
                            .toLowerCase()
                            .startsWith(input.toLowerCase()) ||
                            item.lastName
                                .toLowerCase()
                                .startsWith(input.toLowerCase())) &&
                        !authorsAdded.some((x) => x.id === item.id),
                ),
            );
        } else {
            setAuthorsFound([]);
        }
    };

    const addAuthor = (id: string, authorName: string | null) => {
        AuthorService.addPocAuthor(props.pocId, id)
            .then(() => {
                toast.success(
                    t("result.successfullyAdded", {
                        label: authorName,
                    }),
                );
            })
            .catch(() => {
                toast.error(t("author.addAuthorError"));
            });
    };

    const sendHomeAfterRemoveLoggedUser = () => {
        navigate("/", { replace: true });
    };

    const removeAuthor = (id: string, authorName: string | null) => {
        AuthorService.removePocAuthor(props.pocId, id)
            .then(() => {
                toast.success(
                    t("result.successfullyRemoved", {
                        label: authorName,
                    }),
                );
                if (loggedUserId === id) {
                    sendHomeAfterRemoveLoggedUser();
                }
            })
            .catch(() => {
                toast.error(t("author.removeAuthorError"));
            });
    };

    return (
        <Modal
            isOpen={props.isModalOpen}
            onClosed={() => {
                setAuthorsFound([]);
                setSearchTerm("");
            }}
            toggle={props.toggleModal}
            className="poc-modal modal-lg">
            <Card className="poc-card" style={{ maxWidth: "100%" }}>
                <div className="modal-header">
                    <CardTitle className="modal-title">
                        {t("author.addOrRemove")}
                    </CardTitle>
                    <CloseButton onClick={props.toggleModal} />
                </div>
                <CardBody
                    style={{ minHeight: "400px" }}
                    className="table-responsive">
                    <h2>{t("author.available")}</h2>
                    <div>
                        <InputGroup className="authorsSearch">
                            <Input
                                placeholder={t("author.searchAvailableAuthors")}
                                value={searchTerm}
                                onChange={(e) =>
                                    handleSearch(
                                        e.target.value.toString().trim(),
                                    )
                                }
                            />
                        </InputGroup>

                        <AddAndRemoveLists
                            addItem={addAuthor}
                            removeItem={removeAuthor}
                            getListItemLabel={(item) => item.name}
                            getListItemId={(item) => item.externalUserId}
                            itemsFound={authorsFound}
                            setItemsFound={setAuthorsFound}
                            itemsAdded={authorsAdded}
                            setItemsAdded={setAuthorsAdded}
                            itemsAddedLabel={t("author.associatedAuthors")}
                            loggedUserId={loggedUserId}
                        />
                    </div>
                </CardBody>
            </Card>
        </Modal>
    );
};

export default AddAndRemoveAuthorsModal;
