import { useState } from "react";
import { Modal, Button, Card, CardFooter, CloseButton } from "reactstrap";
import SuggestionService from "../services/SuggestionService";
import toast from "react-hot-toast";
import { Trash3 } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { handleSubmitFormErrorResponse } from "utils/FormUtil";
import "../styles/ModalDialogFooter.scss";

interface DeleteSuggestionProps {
    suggestionId: number;
    onDeleteSuccess: () => void;
}

const DeleteSuggestion: React.FC<DeleteSuggestionProps> = ({
    suggestionId,
    onDeleteSuccess,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submitError, setSubmitError] = useState<string>("");
    const { t } = useTranslation();

    const toggleModal = () => setIsModalOpen(!isModalOpen);

    const handleDelete = async () => {
        try {
            await SuggestionService.deleteSuggestion(suggestionId);
            toast.success(t("toast.successDeletingSuggestion"));
            toggleModal();
            onDeleteSuccess();
        } catch (error) {
            console.error("Error deleting suggestion:", error);
            handleSubmitFormErrorResponse(
                error,
                null,
                setSubmitError,
                () => {},
                t,
            );
            toast.error(t("toast.errorDeletingSuggestion"));
        }
    };

    return (
        <>
            <Button onClick={toggleModal}>
                <Trash3 color="#E23D28" size={30} />
            </Button>

            <Modal
                isOpen={isModalOpen}
                toggle={toggleModal}
                className="suggestion-modal">
                <Card className="suggestion-card">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {t("deleteSuggestion.confirmDeleteDialog")}
                        </h5>
                        <CloseButton onClick={toggleModal} />
                    </div>
                    <CardFooter className="modal-suggestion-footer">
                        <Button color="danger" onClick={handleDelete}>
                            {t("deleteSuggestion.delete")}
                        </Button>
                        <Button color="secondary" onClick={toggleModal}>
                            {t("deleteSuggestion.cancel")}
                        </Button>
                    </CardFooter>
                    {submitError && (
                        <div className="error-message">{submitError}</div>
                    )}
                </Card>
            </Modal>
        </>
    );
};

export default DeleteSuggestion;
