import React, { useState, useEffect } from "react";
import {
    Modal,
    Button,
    Table,
    Card,
    CardBody,
    CardTitle,
    CardFooter,
    CloseButton,
} from "reactstrap";
import { Link, Trash } from "react-bootstrap-icons";
import PocService from "../services/PocService";
import { fileIconMapper } from "../utils/FileIconMapper";
import UploadArticleFile from "./UploadArticleFile";
import { handleSubmitFormErrorResponse } from "../utils/FormUtil";
import toast from "react-hot-toast";
import "../styles/ManageArticleFiles.scss";
import "../styles/ModalDialogFooter.scss";

import { useTranslation } from "react-i18next";
interface FileItem {
    fileUrl: string;
    fileName: string;
    mimeType: string;
    [key: string]: string;
}

const ManageArticleFiles: React.FC<{ pocId: number }> = ({ pocId }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [files, setFiles] = useState<FileItem[]>([]);
    const [sortField, setSortField] = useState("fileName");
    const [sortAscending, setSortAscending] = useState(true);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [fileToDelete, setFileToDelete] = useState<FileItem | null>(null);
    const [submitError, setSubmitError] = useState<string>("");
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState("");

    const toggleModal = () => setIsOpen(!isOpen);

    const toggleDeleteConfirmModal = () => {
        setShowDeleteConfirm(!showDeleteConfirm);
        setSubmitError("");
    };

    const sortFiles = (field: string) => {
        const isAscending = field === sortField ? !sortAscending : true;
        setSortField(field);
        setSortAscending(isAscending);

        const sortedFiles = [...files].sort((a, b) => {
            if (a[field] < b[field]) {
                return isAscending ? -1 : 1;
            }
            if (a[field] > b[field]) {
                return isAscending ? 1 : -1;
            }
            return 0;
        });

        setFiles(sortedFiles);
    };
    const filteredFiles = searchTerm
        ? files.filter((file) =>
              file.fileName.toLowerCase().includes(searchTerm.toLowerCase()),
          )
        : files;
    const copyToClipboard = (text: string) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                toast.success(t("toast.linkCopied"));
            })
            .catch(() => {
                toast.error(t("toast.errorLinkCopy"));
            });
    };

    const loadFiles = async () => {
        if (pocId !== null) {
            try {
                const response = await PocService.fetchFiles(pocId);
                setFiles(response.data);
            } catch (error) {
                console.error("Error fetching files:", error);
                toast.error(t("toast.errorFetching"));
            }
        }
    };

    useEffect(() => {
        loadFiles();
    }, [pocId]);

    const onUploadSuccess = () => {
        loadFiles();
    };

    const requestDeleteFile = (file: FileItem) => {
        setFileToDelete(file);
        setShowDeleteConfirm(true);
        setSubmitError("");
    };

    const confirmDelete = async () => {
        if (fileToDelete && pocId) {
            let deletionSuccessful = false;
            try {
                await PocService.deleteFile(pocId, fileToDelete.fileName);
                toast.success(t("articleFiles.fileDeletedSuccessfully"));
                deletionSuccessful = true;
            } catch (error) {
                console.error("Error deleting file:", error);
                handleSubmitFormErrorResponse(
                    error,
                    null,
                    setSubmitError,
                    () => {},
                    t,
                );
                toast.error(t("toast.errorDeletingFile"));
            }

            if (deletionSuccessful) {
                setShowDeleteConfirm(false);
            }
            await loadFiles();
        }
    };

    return (
        <>
            <Button
                className="btn btn-primary mt-3 me-4 mb-3"
                type="submit"
                color="primary"
                onClick={toggleModal}>
                {t("articleFiles.manageFiles")}
            </Button>
            <Modal
                isOpen={isOpen}
                toggle={toggleModal}
                className="poc-modal modal-lg">
                <Card className="poc-card" style={{ maxWidth: "100%" }}>
                    <div className="modal-header">
                        <CardTitle className="modal-title">
                            {t("articleFiles.pocFiles")}
                        </CardTitle>
                        <CloseButton onClick={toggleModal} />
                    </div>
                    <CardBody className="table-responsive">
                        <input
                            type="text"
                            placeholder={t("articleFiles.searchFiles")}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="form-control mb-3"
                        />
                        <Table className="invisible-lines-table">
                            <thead>
                                <tr>
                                    <th>{t("articleFiles.icon")}</th>
                                    <th
                                        className="file-name"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sortFiles("fileName")}>
                                        {t("articleFiles.fileName")}{" "}
                                        {sortField === "fileName" &&
                                            (sortAscending ? "▲" : "▼")}
                                    </th>
                                    <th
                                        className="mime-type"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => sortFiles("mimeType")}>
                                        {t("articleFiles.mimeType")}{" "}
                                        {sortField === "mimeType" &&
                                            (sortAscending ? "▲" : "▼")}
                                    </th>
                                    <th>{t("articleFiles.actions")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredFiles.map((file, index) => (
                                    <tr key={index}>
                                        <td>{fileIconMapper(file.mimeType)}</td>
                                        <td className="file-name">
                                            <a
                                                href={file.fileUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                title={`${file.fileName}`}>
                                                {file.fileName}
                                            </a>
                                        </td>
                                        <td
                                            className="mime-type"
                                            title={`${file.mimeType}`}>
                                            {file.mimeType}
                                        </td>
                                        <td className="action-buttons">
                                            <Button
                                                color="primary"
                                                size="sm"
                                                onClick={() =>
                                                    copyToClipboard(
                                                        file.fileUrl,
                                                    )
                                                }>
                                                <Link size={16} />
                                            </Button>{" "}
                                            <Button
                                                color="danger"
                                                size="sm"
                                                onClick={() =>
                                                    requestDeleteFile(file)
                                                }>
                                                <Trash size={16} />
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </CardBody>
                    <CardFooter>
                        <UploadArticleFile
                            pocId={pocId}
                            onUploadSuccess={onUploadSuccess}
                        />
                    </CardFooter>
                </Card>
            </Modal>
            <Modal
                isOpen={showDeleteConfirm}
                toggle={toggleDeleteConfirmModal}
                className="poc-modal">
                <Card className="poc-card">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {t("articleFiles.confirmDelete")}
                        </h5>
                        <CloseButton
                            onClick={toggleDeleteConfirmModal}
                            className="ml-auto"
                        />
                    </div>
                    <CardFooter className="modal-poc-footer">
                        <Button color="danger" onClick={confirmDelete}>
                            {t("articleFiles.delete")}
                        </Button>
                        <Button
                            color="secondary"
                            onClick={() => setShowDeleteConfirm(false)}>
                            {t("articleFiles.cancel")}
                        </Button>
                    </CardFooter>
                </Card>
                {submitError && (
                    <div className="error-message">{submitError}</div>
                )}
            </Modal>
        </>
    );
};

export default ManageArticleFiles;
