import { useState } from "react";
import { Col, Row } from "reactstrap";
import Layout from "../layouts/Layout";
import FilterGrid from "components/PocsFilterGrid";
import SuggestionFilterGrid from "components/SuggestionFilterGrid";
import { FormGroup, Label, Input } from "reactstrap";
import { useTranslation } from "react-i18next";
import AuthService from "auth/AuthService";
function Home() {
    const [viewMode, setViewMode] = useState("PoCs");
    const { t } = useTranslation();
    return (
        <Layout>
            <Row>
                <Col xs="12">
                    <div className="home">
                        {AuthService.isAuthenticated() && (
                            <>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="viewMode"
                                            value="PoCs"
                                            checked={viewMode === "PoCs"}
                                            onChange={() => setViewMode("PoCs")}
                                        />
                                        PoCs
                                    </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="viewMode"
                                            value="PoCSuggestions"
                                            checked={
                                                viewMode === "PoCSuggestions"
                                            }
                                            onChange={() =>
                                                setViewMode("PoCSuggestions")
                                            }
                                        />
                                        {t("grid.suggestions")}
                                    </Label>
                                </FormGroup>
                            </>
                        )}
                        {viewMode === "PoCs" ? (
                            <FilterGrid />
                        ) : (
                            <SuggestionFilterGrid />
                        )}
                    </div>
                </Col>
            </Row>
        </Layout>
    );
}

export default Home;
