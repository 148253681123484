import { useEffect, useState } from "react";
import * as runtime from "react/jsx-runtime";
import { evaluate } from "@mdx-js/mdx";
import rehypeHighlight from "rehype-highlight";
import remarkGfm from "remark-gfm";
import rehypeKatex from "rehype-katex";
import remarkMath from "remark-math";

function useMDX(mdxContent) {
    const [exports, setExports] = useState({ default: runtime.Fragment });

    useEffect(() => {
        evaluate(mdxContent, {
            ...runtime,
            useDynamicImport: true,
            rehypePlugins: [rehypeHighlight, rehypeKatex],
            remarkPlugins: [remarkGfm, remarkMath],
            format: "mdx",
        }).then((exports) => setExports(exports));
    }, [mdxContent]);

    return exports;
}

export default useMDX;
