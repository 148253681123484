import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PaginationSection from "components/PaginationSection";
import CenteredLoader from "components/CenteredLoader";
import SuggestionsService from "services/SuggestionsService";
import SuggestionCard from "./SuggestionCard";
import "styles/SuggestionGrid.scss";
import DeleteSuggestion from "./DeleteSuggestion";
import EditSuggestion from "./EditSuggestion";
import AuthService from "auth/AuthService";
import UserRole from "auth/enums/UserRole";
interface Suggester {
    id: number;
    externalUserId: string;
    firstName: string;
    lastName: string;
    shortBio: string;
    pictureUrl: string;
}
interface Suggestion {
    id: number;
    name: string;
    description: string;
    status: string;
    suggester: Suggester;
    dateCreated: string;
    lastUpdated: string;
    totalVotes: number;
}
interface IPaginatedResponse<T> {
    totalCount: number;
    items: T[];
}
interface SuggestionGridProps {
    currentPage: number;
    setCurrentPage: (page: number) => void;
    filterByPocName?: string;
    sortField: string;
    sortOrder: string;
}
const pageSize = 12;
const SuggestionGrid: React.FC<SuggestionGridProps> = ({
    currentPage,
    setCurrentPage,
    filterByPocName,
    sortField,
    sortOrder,
}) => {
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

    const [totalSuggestions, setTotalSuggestions] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const isAdmin = AuthService.hasRole(UserRole.ADMIN);

    const fetchSuggestions = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await SuggestionsService.getSuggestions(
                currentPage,
                pageSize,
                sortField,
                sortOrder,
                filterByPocName,
            );
            const typedResponse: IPaginatedResponse<Suggestion> = {
                totalCount: response.totalCount,
                items: response.items as Suggestion[],
            };
            setSuggestions(typedResponse.items);
            setTotalSuggestions(typedResponse.totalCount);
        } catch (error) {
            console.error("Failed to fetch suggestions", error);
        }
        setIsLoading(false);
    }, [currentPage, sortField, sortOrder, filterByPocName]);

    useEffect(() => {
        fetchSuggestions();
    }, [fetchSuggestions, currentPage]);

    const getTotalPageCount = () => Math.ceil(totalSuggestions / pageSize);
    const handleDeleteSuccess = (id: number) => {
        setSuggestions(
            suggestions.filter((suggestion) => suggestion.id !== id),
        );
    };
    return (
        <div>
            {isLoading && <CenteredLoader />}
            <div className="pocs-grid">
                {!isLoading && suggestions.length > 0
                    ? suggestions.map((suggestion, index) => (
                          <SuggestionCard
                              id={suggestion.id}
                              key={index}
                              name={suggestion.name}
                              description={suggestion.description}
                              suggester={suggestion.suggester}
                              totalVotes={suggestion.totalVotes}
                              deleteButton={
                                  isAdmin ||
                                  suggestion.suggester.externalUserId ===
                                      AuthService.getUserId() ? (
                                      <DeleteSuggestion
                                          suggestionId={suggestion.id}
                                          onDeleteSuccess={() =>
                                              handleDeleteSuccess(suggestion.id)
                                          }
                                      />
                                  ) : null
                              }
                              editButton={
                                  isAdmin ||
                                  suggestion.suggester.externalUserId ===
                                      AuthService.getUserId() ? (
                                      <EditSuggestion
                                          suggestionId={suggestion.id}
                                      />
                                  ) : null
                              }
                          />
                      ))
                    : !isLoading && <div> {t("suggestionGrid.notFound")}</div>}
            </div>
            <PaginationSection
                isLoading={isLoading}
                currentPage={currentPage}
                totalPageCount={getTotalPageCount()}
                setCurrentPage={setCurrentPage}
            />
        </div>
    );
};

export default SuggestionGrid;
