import useMDX from "../hooks/useMDX";

interface MdxContentProps {
    content: string;
}

const MdxContent: React.FC<MdxContentProps> = ({
    content,
}: MdxContentProps) => {
    const exports = useMDX(content);
    const Content = exports.default;

    return <Content />;
};

export default MdxContent;
